import { useCallback, useEffect, useState } from "react";
import styles from "./NotificationBanner.module.css";
import NotificationsService from "../../services/NotificationsService";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";

const NotificationBanner = ({ user }) => {
  const { t } = useTranslation("notifications");
  const [error, setError] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const fetchNotifications = useCallback(async () => {
    return NotificationsService.get(user)
      .then((data) => {
        setNotifications(data);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });
  }, [user]);

  useEffect(() => {
    fetchNotifications().then(() => {
      const intervalId = setInterval(fetchNotifications, 300000);
      return () => clearInterval(intervalId);
    });
  }, [fetchNotifications]);

  const handleAcknowledge = (notification) => {
    NotificationsService.acknowledge(user, notification)
      .then((results) => {
        setNotifications((prev) =>
          prev.filter((p) => p._id !== notification._id)
        );
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleDismiss = (notificationId) => {
    setNotifications((prev) => prev.filter((p) => p._id !== notificationId));
  };

  return (
    <div className={styles.NotificationBanner__container}>
      {notifications.map((notification) => (
        <div
          key={notification._id}
          className={`${styles.NotificationBanner__card} ${
            styles[notification.level]
          }`}
        >
          <div className={styles.NotificationBanner__content}>
            <div className={styles.NotificationBanner__header}>
              <h4>{notification.title}</h4>
              <div className={styles.NotificationBanner__actions}>
                <Button
                  labelName={t("acknowledge")}
                  className={styles.NotificationBanner__acknowledge}
                  onClick={() => handleAcknowledge(notification)}
                  isPrimary={true}
                />
                <button
                  className={styles.NotificationBanner__dismiss}
                  onClick={() => handleDismiss(notification._id)}
                >
                  ✕
                </button>
              </div>
            </div>
            <p>{notification.message}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NotificationBanner;