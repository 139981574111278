import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import Konva from "konva";
import {
  Stage,
  Layer,
  Rect as KonvaRect,
  Text as KonvaText,
  Transformer,
} from "react-konva";
import { pxToMm, mmToPx } from "./functions/converters.js";
import "../../fonts/fontFaces.css";
import Barcode from "./StageComponents/Barcode";
import Image from "./StageComponents/Image";
import Text from "./StageComponents/Text";
import Rect from "./StageComponents/Rect";
import Group from "./StageComponents/Group";
import GridLayout from "./StageComponents/GridLayout";
import { rereferencedObject } from "./functions/objects.js";

Konva.pixelRatio = 4;

const LabelEditorStage = ({
  stageRef,
  width,
  height,
  zoom,
  objects,
  selectedId,
  setSelectedId,
  selectObject,
  updateObject,
  transformerLock,
  transformerReset,
  setTransformerReset,
  updateGroup,
}) => {
  const trRef = useRef();
  const gridCell = useRef(null);
  const backgroundRef = useRef(null);
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [selectedObject, setSelectedObject] = useState(null);

  const components = {
    rect: Rect,
    text: Text,
    image: Image,
    barcode: Barcode,
    group: Group,
    gridLayout: GridLayout,
  };

  useEffect(() => {
    setSelectedObject(selectObject(selectedId));
  }, [selectedId, selectObject]);

  useEffect(() => {
    const loadFonts = async () => {
      try {
        await document.fonts.ready;
        setFontsLoaded(true);
      } catch (error) {
        console.error("Font loading failed:", error);
      }
    };

    loadFonts();
  }, []);

  useEffect(() => {
    if (transformerReset || (selectedId && trRef.current)) {
      setTransformerReset(false);
      if (!selectedId) {
        return;
      }
      const selectedNode = stageRef.current.findOne("#" + selectedId);
      trRef.current.nodes([selectedNode]);
      trRef.current.getLayer().batchDraw();

      if (transformerLock) {
        trRef.current.resizeEnabled(false);
        trRef.current.rotateEnabled(false);
      } else {
        trRef.current.resizeEnabled(true);
        trRef.current.rotateEnabled(true);
        if (selectedNode.className === "Text") {
          trRef.current.enabledAnchors(["middle-left", "middle-right"]);
        } else {
          trRef.current.enabledAnchors([
            "top-left",
            "top-center",
            "top-right",
            "middle-right",
            "middle-left",
            "bottom-left",
            "bottom-center",
            "bottom-right",
          ]);
        }
      }
    } else if (trRef.current) {
      trRef.current.nodes([]);
    }
  }, [selectedId, stageRef, transformerLock, selectedObject, transformerReset, setTransformerReset]);


  const intersectId = useCallback((id) => {
    const intersections = stageRef.current.getAllIntersections(
      stageRef.current.getPointerPosition()
    );
    const activeObject = intersections.find((obj) => {
      const objId = obj.id() || obj?.parent.id();
      return objId === id;
    });

    if (!activeObject) {
      return null;
    } else if (activeObject.id()) {
      return activeObject;
    } else {
      return activeObject.parent;
    }
  }, [stageRef]);

  const hasAncestorNode = (node, ancestorToFind) => {
    let parent = node.getParent();
    while (parent) {
      if (parent === ancestorToFind) {
        return true;
      }
      parent = parent.getParent();
    }
    return false;
  }
  
  const hasAncestorNodeId = useCallback((node, id, checkSelf) => {
    if (checkSelf && node.attrs.id === id) {
      return true;
    } else if (!node.parent || !node.parent.attrs.objectType) {
      return false;
    } else if (node.parent.attrs.id === id) {
      return true;
    } else {
      return hasAncestorNodeId(node.parent, id);
    }
  }, []);


  const modifyObject = (obj, node, parent = null) => {
    const newObj = {
      ...obj,
      x: parent ? obj.x * node.scaleX() : pxToMm(node.x()),
      y: parent ? obj.y * node.scaleY() : pxToMm(node.y()),
      width:
        obj.type !== "text" || parent 
          ? obj.width * node.scaleX() 
          : pxToMm(node.width()),
      height:
        obj.type !== "text" || parent
          ? obj.height * node.scaleY()
          : pxToMm(node.height()),
      ...(parent ? { parent } : { rotation: node.getRotation() }),
      ...(obj.fontSize ? { fontSize: obj.fontSize * node.scaleY() } : {}),
    };
    if (obj.objects) {
      newObj.objects = obj.objects.map((o) => modifyObject(o, node, newObj));
    }
    return newObj;
  };

  const modifyGroupedObject = (obj, node) => {
    const oldGroup = selectObject(obj.parent.id);
    const index = oldGroup.objects.findIndex((o) => o.id === obj.id);
    const newObjects = [
      ...oldGroup.objects.slice(0, index),
      modifyObject(obj, node),
      ...oldGroup.objects.slice(index + 1),
    ];

    const newGroup = updateGroup({
      ...oldGroup,
      objects: newObjects,
    });

    return newGroup;
  };

  const modifyGridLayoutObject = (obj, node) => {
    const newWidths = obj.widths.slice().map((w) => w * node.scaleX());
    const newHeights = obj.heights.slice().map((h) => h * node.scaleY());
    const newObj = {
      ...obj,
      x: pxToMm(node.x()),
      y: pxToMm(node.y()),
      widths: newWidths,
      heights: newHeights,
      width: newWidths.reduce((sum, w) => sum + w),
      height: newHeights.reduce((sum, h) => sum + h),
      rotation: node.getAbsoluteRotation(),
    };
    if (obj.objects) {
      newObj.objects = obj.objects.map((o) => ({
        ...o,
        parent: newObj,
        x: o.x * node.scaleX(),
        y: o.y * node.scaleY(),
      }));
    }
    return newObj;
  };

  const removeNestedObject = (objArray, dropId) => {
    return (
      objArray
        .filter((o) => o.id !== dropId)
        .map((o) => ({
          ...o,
          ...(o.objects ? {objects: removeNestedObject(o.objects, dropId)} : {})
        }))
    )
  }

  const addGridObject = (obj) => {
    const row = gridCell.current.attrs.row;
    const col = gridCell.current.attrs.col;
    const gridNode = gridCell.current.parent;
    const gridObject = selectObject(gridNode.id());

    let newObjects = [
      ...removeNestedObject(gridObject.objects, obj.id),
      { ...obj, row, col },
    ];

    const newGrid = rereferencedObject({
      ...gridObject,
      objects: newObjects,
    }, gridObject.parent);

    return newGrid;
  };

  const addAncestryPosition = (obj, parent) => {
    obj.x += parent.x;
    obj.y += parent.y
    if (parent.parent) {
      return addAncestryPosition(obj, parent.parent)
    }
    return obj
  }

  const removeGridObject = (obj, node) => {
    const { row, col, parent, ...newObj } = obj;
    newObj.x = pxToMm(node.x());
    newObj.y = pxToMm(node.y());
    return addAncestryPosition(newObj, obj.parent);
  };
  
  const handleStageClick = (e) => {
    const selectObjectId = (object) => {
      if (
        ["barcode", "group", "gridLayout", "resizeLine"].includes(
          object.attrs.objectType
        )
      ) {
        return object.parent.id();
      } else {
        return object.id();
      }
    };

    if (e.target === e.target.getStage()) {
      setSelectedId(null);
    } else {
      const position = stageRef.current.getRelativePointerPosition();
      position.x = position.x * (zoom / 100);
      position.y = position.y * (zoom / 100);

      const clickedNodes = stageRef.current.getAllIntersections(position);

      const filteredNodes = clickedNodes
        .filter((node) => node.attrs.objectType !== "resizeLine")
        .filter(
          (node) =>
            !clickedNodes.some(
              (n) => (
                n._id !== node._id &&
                n.parent &&
                n.parent.attrs.objectType &&
                n.parent.attrs.id === node.attrs.parentId
              )
            )
        );

      if (filteredNodes.length > 1) {
        const smallestObject = filteredNodes.reduce((smallest, current) => {
          const currentArea = current.width() * current.height();
          const smallestArea = smallest.width() * smallest.height();
          return currentArea < smallestArea ? current : smallest;
        });

        setSelectedId(selectObjectId(smallestObject));
      } else if (filteredNodes.length === 1) {
        setSelectedId(selectObjectId(filteredNodes[0]));
      }
    }
  };
  
  const handleMouseMove = useCallback((e) => {
    if (e.target.attrs.objectType === "resizeLine" && !e.target.parent.attrs.locked) {
      if (e.target.attrs.orientation === "vertical") {
        e.currentTarget.container().style.cursor = "ew-resize"        
      } else {
        e.currentTarget.container().style.cursor = "ns-resize"
      }
    } else if (e.target.attrs.objectType) {
      if (hasAncestorNodeId(e.target, selectedId, true) || intersectId(selectedId)) {
        e.currentTarget.container().style.cursor = "move"
      } else {
        e.currentTarget.container().style.cursor = "pointer"
      }
    } else {
      e.currentTarget.container().style.cursor = "default"
    }
  }, [selectedId, hasAncestorNodeId, intersectId])

  const handleDragStart = useCallback(
    (e) => {
      if (e.target.attrs.objectType === "resizeLine") {
        return;
      }

      const draggedObject = e.target;
      const activeObject = intersectId(selectedId);

      if (activeObject && !activeObject.attrs.locked) {
        if (
          draggedObject !== activeObject ||
          hasAncestorNode(draggedObject, activeObject)
        ) {
          e.target.stopDrag();
          activeObject.startDrag(e);
        }
      } else {
        e.target.stopDrag();
      }
    },
    [selectedId, intersectId]
  );

  const handleDragMove = (e) => {
    if (e.target.attrs.objectType === "resizeLine") return;
    if (e.target.parent?.attrs.objectType === "group") return;

    const intersections = stageRef.current.getAllIntersections(
      stageRef.current.getPointerPosition()
    );

    const hoveringGridCell = intersections.find(
      (node) => (
        node.attrs.objectType === "gridLayout" && 
        !hasAncestorNode(node, e.target)
      
      )
    );

    if (hoveringGridCell) {
      backgroundRef.current.fill("white");
      if (!gridCell.current) {
        gridCell.current = hoveringGridCell;
      }

      if (hoveringGridCell.parent.attrs.objects.some((o) => o.row === hoveringGridCell.attrs.row && o.col === hoveringGridCell.attrs.col)) {
        hoveringGridCell.fill("#cb7841");
      } else {
        hoveringGridCell.fill("#089bab");        
      }

    } else if (
      !hoveringGridCell &&
      e.target?.parent?.attrs?.objectType === "gridLayout"
    ) {
      backgroundRef.current.fill("#089bab80");
      gridCell.current?.fill(null);
    }

    if (gridCell.current && gridCell.current !== hoveringGridCell) {
      gridCell.current.fill(null);
      gridCell.current = hoveringGridCell;
    }
  };

  const handleDragEnd = (e) => {
    if (e.target.attrs.objectType === "resizeLine") {
      return;
    }

    e.target.preventDefault();
    const activeObject = intersectId(selectedId);
    if (activeObject !== e.target) {
      return;
    }
    const id = e.target.id();
    const obj = selectObject(id);
    if (!obj) {
      return;
    }

    if (obj.parent && obj.parent.type === "group") {
      const draggedObjParent = modifyGroupedObject(obj, e.target);
      updateObject(draggedObjParent);
    } else if (gridCell.current) {
      if (gridCell.current.fill() === "#089bab") {
        const gridLayout = addGridObject(obj);
        updateObject(gridLayout, obj.id);
      } else {
        setSelectedId(null)
      }      
    } else if (
      !gridCell.current &&
      obj.parent &&
      obj.parent.type === "gridLayout"
    ) {
      const removedObj = removeGridObject(obj, e.target);
      updateObject(removedObj, obj.id);
    } else {
      const draggedObj = modifyObject(obj, e.target);
      updateObject(draggedObj);
    }

    backgroundRef.current.fill("white");
    if (gridCell.current) {
      gridCell.current.fill(null);
      gridCell.current = null;
    }
  };

  const handleTransformEnd = (e) => {
    const id = e.target.id();
    const obj = selectObject(id);
    const node = e.target;

    if (obj.parent && obj.parent.type === "group") {
      const modifiedGroupObj = modifyGroupedObject(obj, e.target);
      updateObject(modifiedGroupObj);
    } else if (obj.type === "gridLayout") {
      const modifiedGridObj = modifyGridLayoutObject(obj, e.target);
      updateObject(modifiedGridObj);
    } else {
      const modifiedObj = modifyObject(obj, e.target);
      updateObject(modifiedObj);
    }

    node.scaleX(1);
    node.scaleY(1);
  };

  const MemoizedStage = useMemo(() => {
    return () => {
      return (
        <Stage
          ref={stageRef}
          key={"stage"}
          container={"stage"}
          width={(mmToPx(width) * zoom) / 100}
          height={(mmToPx(height) * zoom) / 100}
          onMouseMove={handleMouseMove}
          onClick={handleStageClick}
          scaleX={zoom / 100}
          scaleY={zoom / 100}
        >
          <Layer key={"layer"}>
            <KonvaRect
              ref={backgroundRef}
              id={"background"}
              key={"background"}
              x={0}
              y={0}
              width={mmToPx(width)}
              height={mmToPx(height)}
              fill={"white"}
              listening={false}
            />

            {["Poppins", "Roboto", "Open Sans"].map((fontFamily) =>
              ["normal", "bold", "italic", "bold italic"].map((fontStyle) => (
                <KonvaText
                  key={`fontLoader-${fontFamily}-${fontStyle}`}
                  text={""}
                  x={0}
                  y={0}
                  fontFamily={fontFamily}
                  fontStyle={fontStyle}
                  visible={false}
                />
              ))
            )}

            {objects.toReversed().map((obj) => {
              const Component = components[obj.type];

              if (!Component) return null;

              return (
                <Component
                  obj={obj}
                  key={obj.id}
                  updateObject={updateObject}
                  onDragStart={handleDragStart}
                  onDragMove={handleDragMove}
                  onDragEnd={handleDragEnd}
                  onTransformEnd={handleTransformEnd}
                  setTransformerReset={setTransformerReset}
                  selectObject={selectObject}
                />
              );
            })}

            <Transformer
              ref={trRef}
              ignoreStroke={true}
              rotationSnaps={Array.from({ length: 360 / 15 }, (_, i) => i * 15)}
              rotateAnchorOffset={25}
              flipEnabled={false}
              boundBoxFunc={(oldBox, newBox) => (
                newBox.width < 5 || newBox.height < 5 
                  ? oldBox
                  : newBox
              )}
            />
          </Layer>
        </Stage>
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objects, height, width, zoom, selectedId, selectObject, updateObject]);

  if (!fontsLoaded) {
    return <div></div>;
  }

  return <MemoizedStage />;
};

export default LabelEditorStage;
