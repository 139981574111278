import React, { useEffect } from "react";
import styles from "./DailyTotalBarChart.module.css";
import "./DailyTotalBarChart.css";
import {
  ChartComponent,
  Tooltip,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  ColumnSeries,
  Category,
  Legend,
  DataLabel,
  Selection,
} from "@syncfusion/ej2-react-charts";

const palette = [
  "#218678",
  "#218678",
  "#218678",
  "#218678",
  "#218678",
  "#218678",
  "#218678",
];

function DailyTotalBarChart({
  activeKey,
  data,
  chartWidth,
  columnWidth,
  columnSpacing,
  selectedDay,
  onSelectedDay,
  onProductsSelectedDay,
  classNameForBargraph,
  onClassNameForBargraph,
  productSelectionLookup,
  onProductSelectionLookup,
  onNullifyApplicableDates,
}) {
  useEffect(() => {
    if (selectedDay === null) {
      onClassNameForBargraph("DailyTotalBarChart__allDaysSelected");
    }
  }, [selectedDay, onClassNameForBargraph]);

  function chartTemplate(args) {
    // console.log("args: ", args);
    return (
      <div
        id="templateWrap"
        className={
          args.index === 5 || args.index === 6
            ? `${styles.DailyBarChart__tooltip} ${styles.DailyTotalBarChart__tooltipPosition2}`
            : `${styles.DailyBarChart__tooltip} ${styles.DailyTotalBarChart__tooltipPosition1}`
        }
      >
        <table className={styles.DailyTotalBarChart__table}>
          <tbody>
            <tr>
              <th>
                DATE
                <span>QTY Filled</span>
              </th>
            </tr>
            <tr>
              <td>01/01/25</td>
              <td>{args.index}</td>
            </tr>
            <tr>
              <td>01/18:25</td>
              <td>{args.index}</td>
            </tr>
            <tr>
              <td>01/15/25</td>
              <td>{args.index}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  const template = chartTemplate;

  const tooltip = {
    enable: false,
    duration: 100,
    enableAnimation: true,
    template: template,
  };

  const pointClick = (args) => {
    let tempRowStatus = { ...productSelectionLookup };

    for (const [key, value] of Object.entries(tempRowStatus)) {
      if (value.selected) {
        value.selected = false;
      }
    }
    onProductSelectionLookup(tempRowStatus);

    let tempSelectedDay = null;

    if (selectedDay !== args.pointIndex) {
      tempSelectedDay = args.pointIndex;

      switch (tempSelectedDay) {
        case 0:
          onClassNameForBargraph("DailyTotalBarChart__SundaySelected");
          break;
        case 1:
          onClassNameForBargraph("DailyTotalBarChart__MondaySelected");
          break;
        case 2:
          onClassNameForBargraph("DailyTotalBarChart__TuesdaySelected");
          break;
        case 3:
          onClassNameForBargraph("DailyTotalBarChart__WednesdaySelected");
          break;
        case 4:
          onClassNameForBargraph("DailyTotalBarChart__ThursdaySelected");
          break;
        case 5:
          onClassNameForBargraph("DailyTotalBarChart__FridaySelected");
          break;
        case 6:
          onClassNameForBargraph("DailyTotalBarChart__SaturdaySelected");
          break;
        default:
          onClassNameForBargraph("DailyTotalBarChart__allDaysSelected");
      }
      onSelectedDay(tempSelectedDay);
      onProductsSelectedDay(args.pointIndex);
    } else {
      onSelectedDay(null);
      onProductsSelectedDay([]);
      onClassNameForBargraph("DailyTotalBarChart__allDaysSelected");
      onNullifyApplicableDates();
    }
  };

  const ChartTray = (
    <div className={classNameForBargraph}>
      <ChartComponent
        // delayUpdate={true}
        tooltip={tooltip}
        animation={{ enable: true }}
        id={activeKey}
        key={activeKey}
        selectionMode="Point"
        palettes={palette}
        pointClick={pointClick}
        style={{ textAlign: "center", height: "300px", width: chartWidth }}
        primaryXAxis={{
          valueType: "Category",
          majorGridLines: { width: 0 },
          majorTickLines: { width: 0 },
          minorTickLines: { width: 0 },
        }}
        load={load.bind(this)}
        primaryYAxis={{
          majorGridLines: { width: 0 },
          majorTickLines: { width: 0 },
          lineStyle: { width: 0 },
          labelStyle: {
            color: "transparent",
          },
        }}
        legendSettings={{ visible: false }}
        // title="Daily Tray total"
        width="100%"
        loaded={onChartLoad.bind(this)}
      >
        <Inject
          services={[
            ColumnSeries,
            Category,
            Legend,
            Tooltip,
            DataLabel,
            Selection,
          ]}
        />
        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={data}
            xName="x"
            yName="y"
            type="Column"
            columnWidth={columnWidth}
            columnSpacing={columnSpacing}
            name="Dispensed"
            marker={{
              dataLabel: {
                visible: true,
                position: "Top",
                font: { size: "13px", fontWeight: "600", color: "#ffffff" },
              },
            }}
          ></SeriesDirective>
        </SeriesCollectionDirective>
      </ChartComponent>
    </div>
  );

  return ChartTray;
  function onChartLoad(args) {
    // let chart = document.getElementById(activeKey);
    // chart.setAttribute("title", "Daily Tray Total");
  }

  function load(args) {
    let selectedTheme = "Fluent2";
    args.chart.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/-dark/i, "Dark")
      .replace(/contrast/i, "Contrast")
      .replace(/-highContrast/i, "HighContrast");
  }
}
export default DailyTotalBarChart;
