import axios from "axios";
import ApiService from "./ApiService";

class ClientFDBService extends ApiService {
  static async getImagesByNdc(user, ndc) {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/fdb/images?ndc=${ndc}`,
        rc
      );
      return response.data.images;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  }

  static async getFdbPackagedDrugByNdc(user, ndc) {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/fdb?ndc=${ndc}`,
        rc
      );
      return response.data.fdbPackagedDrug;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  }

  static async getFdbModifications(user) {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      const url = `${ApiService.BASE_URL}/accounts/${user.account._id}/fdb/modifications`;
      const response = await axios.get(url, rc);
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  }
}

export default ClientFDBService;
