import {
  DndContext,
  closestCenter,
  DragOverlay,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SortableItem from "./SortableItem";
import LayerItem from "./LayerItem";
import DropIndicator from "./DropIndicator";
import { useTranslation } from "react-i18next";
import { 
  hasAncestorType, 
  hasAncestorId, 
  addLineagePosition, 
  removeLineagePosition 
} from "../functions/objects";

const SortableLayerItems = ({
  objects,
  insertHistory,
  updateObject,
  selectedId,
  setSelectedId,
  updateGroup,
  selectObject,
  viewerMode,
  layersScrollPosition,
  setLayersScrollPosition
}) => {
  const { t } = useTranslation("labelEditor");
  const [activeId, setActiveId] = useState(null);
  const [dropTarget, setDropTarget] = useState(null);
  const [containerRect, setContainerRect] = useState(null);
  const containerRef = useRef(null);

  useEffect(() => {
    containerRef.current.scrollTop = layersScrollPosition;  
  }, [containerRef, layersScrollPosition])

  const withDisplayNames = useCallback((arr) => {
    const counts = {
      rect: {},
      image: {},  
      text: {},
      barcode: {},
      group: {},
      gridLayout: {},
    };
  
    const types = {
      rect: t("rectangleLabel"),
      image: t("imageLabel"),
      text: t("textLabel"),
      barcode: t("barcodeLabel"),
      group: t("groupLabel"),
      gridLayout: t("gridLayoutLabel"),
    };
  
    const processObjects = (objects) => {
      return objects.map((item) => {
        let name = item?.mappingName || item?.name || types[item.type];

        if (counts[item.type][name]) {
          counts[item.type][name] += 1;
          name = `${t(name, {ns: "canisterData"})} (${counts[item.type][name]})`;
        } else {
          counts[item.type][name] = 1;
        }
  
        const processedItem = {
          ...item,
          displayName: t(name, {ns: "canisterData"}),
        };
  
        if (hasAncestorType(item, "gridLayout", true) && item.objects && Array.isArray(item.objects)) {
          processedItem.objects = processObjects(item.objects);
        }
  
        return processedItem;
      });
    };
  
    const idIndex = arr.map((obj) => obj.id);
  
    const namedObjects = processObjects([...arr].sort((a, b) => Number(a.id) - Number(b.id)));
  
    return idIndex.map((id) => namedObjects.find((obj) => id === obj.id));
  }, [t]);
  
  const replaceObjectInplace = (newObject, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === newObject.id) {
        array[i] = newObject;
        return true;
      }
      
      if (array[i].objects && Array.isArray(array[i].objects)) {
        if (replaceObjectInplace(newObject, array[i].objects)) {
          return true;
        }
      }
    }
    
    return false;
  }
  
  useEffect(() => {
    // Computed layers bouding box for adjusting height of drop indicator

    if (containerRef.current) {
      const updateContainerRect = () => {
        setContainerRect(containerRef.current.getBoundingClientRect());
      };

      updateContainerRect();
      window.addEventListener("resize", updateContainerRect);
      return () => window.removeEventListener("resize", updateContainerRect);
    }
  }, []);

  const incrementNestedDepth = useCallback((nestedObjects, depth) => {   
    return nestedObjects.map(o => ({
      ...o,
      depth: depth + 1,
      objects: o.objects ? incrementNestedDepth(o.objects, depth + 1) : null
    })) 
  }, [])

  const flattenObjects = useCallback((objects, depth = 0, parent = null) => {
    // Convert nested objects array to flat array preserving depth data for layers panel

    return objects.reduce((acc, obj) => {
      const newObj = { ...obj, depth, parent };
      acc.push(newObj);
      if (obj.type === "gridLayout") {
        newObj.objects = incrementNestedDepth(obj.objects, depth)
      } else if (obj.objects && obj.objects.length > 0) {
        acc.push(...flattenObjects(obj.objects, depth + 1, newObj));
      }
      return acc;
    }, []);
  }, [incrementNestedDepth]);

  const flattenedObjects = useMemo(() => {
    const flat = flattenObjects(objects)
    return withDisplayNames(flat)
  } ,[objects, flattenObjects, withDisplayNames]);

  const reconstructObjects = (flatArray) => {
    // Convert flat array to nested objects array for updating current state

    const result = [];
    const map = {};

    flatArray.forEach((item) => {
      const { id, objects, ...rest } = item;
      const newItem = {
        id,
        ...rest,
        objects: item.type === "gridLayout" ? objects : [],
      };
      map[id] = newItem;

      if (activeId === id) {
        newItem.depth = dropTarget.depth;
        newItem.parent = map[dropTarget.parentId] || null;
      }

      if (newItem.depth === 0) {
        result.push(newItem);
      } else if (
        newItem.parent &&
        newItem.parent.id in map &&
        newItem.parent.type !== "gridLayout"
      ) {
        map[newItem.parent.id].objects.push(newItem);
      }
    });

    return result;
  };

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragOver = (event) => {
    // Identify currently hovered drop target (depth 0 or in group nesting)

    const { active, over } = event;
    if (over) {
      const overItem = flattenedObjects.find((item) => item.id === over.id);

      if (!overItem.parent || overItem.parent.type !== "gridLayout") {
        const activeIndex = flattenedObjects.findIndex(
          (item) => item.id === active.id
        );
        const overIndex = flattenedObjects.findIndex(
          (item) => item.id === over.id
        );

        let newDepth = overItem.depth;
        let parentId = overItem.parent?.id;

        if (activeIndex < overIndex) {
          newDepth = overItem.depth;

          if (overItem.type === "group") {
            newDepth += 1;
            parentId = overItem.id;
          } else if (flattenedObjects.length - 1 === overIndex) {
            newDepth = 0;
            parentId = null;
          } else if (flattenedObjects[overIndex + 1].depth < overItem.depth) {
            newDepth = flattenedObjects[overIndex + 1].depth;
            parentId = flattenedObjects[overIndex + 1].parent?.id;
          }
        } else if (overIndex > 0) {
          newDepth = Math.min(
            flattenedObjects[overIndex - 1].depth + 1,
            overItem.depth
          );
        }

        setDropTarget({
          id: over.id,
          parentId: parentId || null,
          depth: newDepth,
        });
      }
    } else {
      setDropTarget(null);
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    // Re-ordered object
    if (dropTarget && active.id !== over.id) {
      const activeIndex = flattenedObjects.findIndex(
        (item) => item.id === active.id
      );
      const overIndex = flattenedObjects.findIndex(
        (item) => item.id === over.id
      );

      // Array with moved object and it's descendants
      const activeItem = flattenedObjects[activeIndex];
      let itemsToMove = [activeItem];
      if (activeItem.type === "group") {
        const childrenEndIndex = flattenedObjects.findIndex(
          (item, index) => index > activeIndex && item.depth <= activeItem.depth
        );
        const groupChildren = flattenedObjects.slice(
          activeIndex + 1,
          childrenEndIndex === -1 ? undefined : childrenEndIndex
        );
        itemsToMove = [...itemsToMove, ...groupChildren];
      }

      const newFlattenedObjects = flattenedObjects.filter(
        (item) => !itemsToMove.includes(item)
      );

      let newIndex = newFlattenedObjects.findIndex(
        (item) => item.id === over.id
      );
      if (activeIndex < overIndex) {
        newIndex += 1;
      }

      newFlattenedObjects.splice(newIndex, 0, ...itemsToMove);


      // Adjust nesting if added and/or removed from group
      const updatedFlattenedObjects = newFlattenedObjects.map((item, index) => {
        if (itemsToMove.includes(item)) {

          // Adjust root object position if added and/or removed from group
          if (activeItem.parent?.id !== dropTarget.parentId && itemsToMove[0].id === item.id) {
            if (activeItem.parent) {
              addLineagePosition(item, activeItem.parent)
            }
            if (dropTarget.parentId) {
              removeLineagePosition(item, selectObject(dropTarget.parentId))
            }
          }
          const newDepth = dropTarget.depth + (item.depth - activeItem.depth);
          return { ...item, depth: newDepth };
        }
        return item;
      });

      // Rebuilding nested objects from flat structure
      const newObjects = reconstructObjects(updatedFlattenedObjects);

      // Recalculate group outlines
      let activeGroup, dropGroup;
      if (activeItem.parent?.id !== dropTarget.parentId) {
        if (activeItem.parent) {
          activeGroup = updateGroup({
            ...activeItem.parent,
            objects: activeItem.parent.objects.filter((o) => o.id !== activeItem.id)          
          })
          replaceObjectInplace(activeGroup, newObjects)
        }
        if (dropTarget.parentId) {
          dropGroup = updateGroup(selectObject(dropTarget.parentId, newObjects))           
          replaceObjectInplace(dropGroup, newObjects)
        }
      }

      insertHistory(newObjects);
    }

    setActiveId(null);
    setDropTarget(null);
  };

  const handleSelectedId = (id) => {
    setLayersScrollPosition(containerRef.current.scrollTop);
    setSelectedId(id);
  }

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <div ref={containerRef} style={{ position: "relative", overflowY: "auto" }}>
        <SortableContext
          items={flattenedObjects}
          strategy={verticalListSortingStrategy}
        >
          {flattenedObjects
            .filter(
              (item) => !item.parent || !hasAncestorId(item, activeId)
            )
            .map((item) => (
              <SortableItem key={item.id} id={item.id}>
                <LayerItem
                  item={item}
                  depth={item.depth}
                  updateObject={updateObject}
                  selectedId={selectedId}
                  setSelectedId={handleSelectedId}
                  isDropTarget={dropTarget?.id === item.id}
                  dropTargetDepth={dropTarget?.depth}
                  viewerMode={viewerMode}
                />
              </SortableItem>
            ))}
        </SortableContext>
        <DropIndicator
          activeId={activeId}
          dropTarget={dropTarget}
          flattenedObjects={flattenedObjects}
          containerRect={containerRect}
        />
        <DragOverlay>
          {activeId ? (
            <LayerItem
              item={flattenedObjects.find((item) => item.id === activeId)}
              depth={
                dropTarget?.depth ||
                flattenedObjects.find((item) => item.id === activeId).depth
              }
              updateObject={updateObject}
              selectedId={selectedId}
              setSelectedId={handleSelectedId}
              isDragging={true}
            />
          ) : null}
        </DragOverlay>
      </div>
    </DndContext>
  );
};

export default SortableLayerItems;
