import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { placeholderImage } from "./svgs/placeholderImage.js";
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";
import ModalBackdrop from "../../components/Modal";
import WaitIndicator from "../../components/WaitIndicator.js";
import { ReactComponent as TrashIcon } from "../../assets/trash-icon.svg";
import { ReactComponent as BarcodeSVG } from "./svgs/barcode2.svg";
import { ReactComponent as ImageSVG } from "./svgs/image.svg";
import { ReactComponent as RectSVG } from "./svgs/rectangle2.svg";
import { ReactComponent as TextSVG } from "./svgs/text.svg";
import { ReactComponent as GridLayoutSVG } from "../../assets/grid-layout.svg";
import { ReactComponent as GroupSVG } from "../../assets/group-items.svg";
import { ReactComponent as DuplicateSVG } from "../../assets/duplicate.svg";
import { ReactComponent as UndoSVG } from "../../assets/undo.svg";
import { ReactComponent as RedoSVG } from "../../assets/redo.svg";
import { ReactComponent as CheveronDownSVG } from "../../assets/cheveron-down.svg";
import { ReactComponent as CheveronRightSVG } from "../../assets/cheveron-right.svg";
import styles from "./LabelEditor.module.css";
import { useTranslation } from "react-i18next";
import { L10n } from "@syncfusion/ej2-base";
import LabelEditorStage from "./LabelEditorStage.js";
import LabelProperties from "./Properties/LabelProperties.js";
import GroupProperties from "./Properties/GroupProperties.js";
import GridLayoutProperties from "./Properties/GridLayoutProperties.js";
import RectProperties from "./Properties/RectProperties.js";
import TextProperties from "./Properties/TextProperties.js";
import ImageProperties from "./Properties/ImageProperties.js";
import BarcodeProperties from "./Properties/BarcodeProperties.js";
import { pxToMm } from "./functions/converters.js";
import LabelViewerStage from "./LabelViewerStage.js";
import SheetProperties from "./Properties/SheetProperties.js";
import AreaProperties from "./Properties/AreaProperties.js";
import { calculateBoundingBox } from "./functions/arithmetic.js";
import SortableLayerItems from "./Layers/SortableLayerItems.js";
import {
  cloneObject,
  regenerateObjectIds,
  rereferencedObjects,
  unreferencedObjects,
} from "./functions/objects.js";
import { Tooltip } from "antd";

const LabelEditor = ({
  user,
  label,
  objects,
  setObjects,
  history,
  setHistory,
  labelPresets,
  setLabelPresets,
  presetId,
  setPresetId,
  onResetCreateLabelStatesToDefaults,
  labelName,
  labelType,
  machineType,
  customizedLabel,
  onHideLabelCanvas,
  _controllerRef,
  nodesArrayForUpdate,
  preloaded,
}) => {
  const { t, i18n } = useTranslation("labelEditor");
  const localization = {
    [i18n.language]: {
      colorpicker: {
        Apply: t("applyButtonLabel"),
        Cancel: t("cancelButtonLabel"),
        ModeSwitcher: t("switcherButtonLabel"),
      },
    },
  };
  L10n.load(localization);

  const stageRef = useRef(null);
  const retrofitted = useRef(false);
  const initialized = useRef(false);
  const positionRef = useRef(0);

  // Objects states
  const [selectedId, setSelectedId] = useState(null);
  const [selectedLock, setSelectedLock] = useState(false);
  const [transformerReset, setTransformerReset] = useState(false);
  const [clipboard, setClipboard] = useState(null);

  // Label states
  const [labelWidth, setLabelWidth] = useState(50);
  const [labelHeight, setLabelHeight] = useState(50);

  // Work area states
  const [unit, setUnit] = useState("mm");
  const [viewerMode, setViewerMode] = useState(false);
  const [zoom, setZoom] = useState(100);
  const [viewerZoom, setViewerZoom] = useState(50);
  const [showComponents, setShowComponents] = useState(true);
  const [resizing, setResizing] = useState(false);
  const [updating, setUpdating] = useState(false);

  // Print options states
  const [presetName, setPresetName] = useState("");
  const [sheetWidth, setSheetWidth] = useState(215.9);
  const [sheetHeight, setSheetHeight] = useState(279.4);
  const [rowCount, setRowCount] = useState(1);
  const [colCount, setColCount] = useState(1);
  const [previewOutline, setPreviewOutline] = useState(true);
  const [autoLayout, setAutoLayout] = useState(presetId === null);
  const [manualLayout, setManualLayout] = useState(null);

  // Stage dimensions
  const [areaWidth, setAreaWidth] = useState(null);
  const [areaHeight, setAreaHeight] = useState(null);
  const [leftPanelWidth, setLeftPanelWidth] = useState(260);
  const [rightPanelWidth, setRightPanelWidth] = useState(270);

  // Layers state
  const [layersScrollPosition, setLayersScrollPosition] = useState(0);

  const applyPreset = useCallback(() => {
    if (!initialized.current && label) {
      setSheetWidth(label.sheetWidth || 215.9);
      setSheetHeight(label.sheetHeight || 279.4);
      setLabelWidth(label.labelWidth || 50);
      setLabelHeight(label.labelHeight || 50);
      setRowCount(label.rowCount || 1);
      setColCount(label.colCount || 1);
      setUnit(label.unit || "mm")
      setManualLayout({
        left: label.leftMargin || 0,
        top: label.topMargin || 0,
        rowGap: label.rowGap || 0,
        colGap: label.colGap || 0,
      });
      const preset = labelPresets.find((p) => p._id === presetId);
      if (preset) {
        setPresetName(preset.name);
      }
    } else if (presetId) {
      const preset = labelPresets.find((p) => p._id === presetId);
      if (preset) {
        setPresetName(preset.name);
        setLabelWidth(preset.labelWidth);
        setLabelHeight(preset.labelHeight);
        setSheetWidth(preset.sheetWidth);
        setSheetHeight(preset.sheetHeight);
        setRowCount(preset.rowCount);
        setColCount(preset.colCount);
        setAutoLayout(preset.autoLayout);
        setManualLayout({
          left: preset.leftMargin,
          top: preset.topMargin,
          rowGap: preset.rowGap,
          colGap: preset.colGap,
        });
      } else {
        setPresetName("");
        setPresetId(null);
      }
    } else {
      setPresetName("");
    }

    initialized.current = true;
  }, [label, labelPresets, presetId, setPresetId]);

  useEffect(() => {
    applyPreset();
  }, [label, presetId, applyPreset]);

  useEffect(() => {
    if (autoLayout) {
      const horizontal = sheetWidth - labelWidth * colCount;
      const vertical = sheetHeight - labelHeight * rowCount;

      const xGap = horizontal / (colCount + 1);
      const yGap = vertical / (rowCount + 1);

      setManualLayout({
        top: yGap,
        right: xGap,
        bottom: yGap,
        left: xGap,
        rowGap: yGap,
        colGap: xGap,
      });
    }
  }, [
    autoLayout,
    sheetWidth,
    sheetHeight,
    labelWidth,
    labelHeight,
    rowCount,
    colCount,
  ]);

  const insertHistory = useCallback((newObjects) => {
    setHistory((prevHistory) => [
      newObjects,
      ...prevHistory.slice(positionRef.current, 50),
    ]);
    setObjects(newObjects);
    positionRef.current = 0;
  }, [setHistory, setObjects]);

  const selectHistory = useCallback(
    (position) => {
      positionRef.current = position;
      setObjects(history[position]);
    },
    [history, setObjects]
  );

  const selectObject = useCallback(
    (id, currentObjects) => {
      if (!currentObjects) {
        currentObjects = objects;
      }

      if (!Array.isArray(currentObjects) || currentObjects.length === 0) {
        return null;
      }

      for (let obj of currentObjects) {
        if (obj.id === id) {
          return obj;
        }

        if (Array.isArray(obj.objects)) {
          const nestedResult = selectObject(id, obj.objects);
          if (nestedResult) {
            return nestedResult;
          }
        }
      }

      return null;
    },
    [objects]
  );

  const updateObject = useCallback(
    (updatedObject, dropId) => {
      const updateRecursive = (currentObjects) => {
        return currentObjects
          .filter((o) => o.id !== dropId)
          .flatMap((obj) => {
            if (obj.id === updatedObject.id) {
              return { ...updatedObject };
            } else if (obj?.objects) {
              const updatedChildren = updateRecursive(obj.objects);
              const hasUpdatedChild = updatedChildren.some(
                (child) => child.id === updatedObject.id
              );

              if (hasUpdatedChild) {
                const updatedGroup = {
                  ...obj,
                  objects: updatedChildren,
                };

                if (
                  !updatedObject.parent ||
                  updatedObject.parent.id !== updatedGroup.id
                ) {
                  return [updatedObject, updatedGroup];
                } else {
                  return updatedGroup;
                }
              } else {
                return { ...obj, objects: updatedChildren };
              }
            } else {
              return obj;
            }
          });
      };
      const updatedObjects = updateRecursive(objects);
      if (!selectObject(updatedObject.id, updatedObjects)) {
        updatedObjects.unshift(updatedObject);
      }
      insertHistory(updatedObjects);
      setTransformerReset(true);
    },
    [objects, insertHistory, selectObject]
  );

  const deleteObject = useCallback(
    (dropId) => {
      const deleteRecursive = (currentObjects) => {
        return currentObjects
          .filter((o) => o.id !== dropId)
          .map((obj) => {
            if (obj?.objects) {
              return {
                ...obj,
                objects: deleteRecursive(obj.objects),
              };
            }
            return obj;
          });
      };

      const updatedObjects = deleteRecursive(objects);
      setSelectedId(null);
      insertHistory(updatedObjects);
      setTransformerReset(true);
    },
    [objects, insertHistory]
  );

  const duplicateObject = (id) => {
    const target = selectObject(id);
    if (!target) return;

    const duplicate = regenerateObjectIds(cloneObject(target));

    duplicate.x += 5;
    duplicate.y += 5;

    insertHistory([duplicate, ...objects]);
    setSelectedId(duplicate.id);
  };

  const copyObject = useCallback(
    (id) => {
      const target = selectObject(id);
      if (!target) return;

      const duplicate = cloneObject(target);
      setClipboard(duplicate);
    },
    [selectObject]
  );

  const pasteObject = useCallback(() => {
    const updatedClipboard = regenerateObjectIds(clipboard);

    insertHistory([updatedClipboard, ...objects]);
    setSelectedId(updatedClipboard.id);
    setTransformerReset(true);
  }, [clipboard, objects, insertHistory]);

  const addRect = useCallback(
    (x = 0, y = 0) => {
      const id = Date.now().toString();
      const newObject = {
        type: "rect",
        id: id,
        x: pxToMm(x),
        y: pxToMm(y),
        width: 25.4,
        height: 25.4,
        rotation: 0,
        stroke: "#000000",
        strokeWidth: 0.2,
        fill: "#ffffff",
        fillColor: "#ffffff",
        rounded: false,
        cornerRadius: 1,
        locked: false,
        visible: true,
      };
      insertHistory([newObject, ...objects]);
      setSelectedId(id);
    },
    [insertHistory, objects]
  );

  const addText = useCallback(
    (x = 0, y = 0) => {
      const id = Date.now().toString();
      const newObject = {
        type: "text",
        id: id,
        x: pxToMm(x),
        y: pxToMm(y),
        width: 25.4,
        height: pxToMm(12),
        rotation: 0,
        fontSize: 12,
        fontFamily: "Poppins",
        fontStyle: "normal",
        textDecoration: "",
        wrap: "none",
        wrapLines: 1,
        align: "left",
        stroke: "none",
        fill: "#000000",
        defaultValue: "default",
        mappingName: "Default Value",
        mappingPath: "",
        locked: false,
        visible: true,
      };
      insertHistory([newObject, ...objects]);
      setSelectedId(id);
    },
    [insertHistory, objects]
  );

  const addImage = useCallback(
    (x = 0, y = 0) => {
      const id = Date.now().toString();
      const newObject = {
        type: "image",
        name: t("productImageLabel"),
        id: id,
        x: pxToMm(x),
        y: pxToMm(y),
        rotation: 0,
        mimeType: "image/png",
        base64EncodedString: placeholderImage,
        getImagesFromDatabase: true,
        locked: false,
        visible: true,
      };
      insertHistory([newObject, ...objects]);
      setSelectedId(id);
    },
    [insertHistory, objects, t]
  );

  const addBarcode = useCallback(
    (x = 0, y = 0) => {
      const id = Date.now().toString();
      const newObject = {
        type: "barcode",
        id: id,
        x: pxToMm(x),
        y: pxToMm(y),
        rotation: 0,

        symbology: "code128",
        defaultValues: {
          code128: "Bag 001",
          code39: "BAG 001",
          code39ext: "Bag 001",
          qrcode: "Bag 001",
          datamatrix: "BAG 001",
        },
        showValue: true,
        fontSize: 12,
        fontFamily: "Poppins",
        fontStyle: "normal",
        textDecoration: "",
        mappingName: "Default Value",
        mappingPath: "",
        locked: false,
        visible: true,
      };
      insertHistory([newObject, ...objects]);
      //setSelectedId(id);
    },
    [insertHistory, objects]
  );

  const addGroup = useCallback(() => {
    const id = Date.now().toString();
    let newObj;
    if (selectedId) {
      const obj = objects.find((obj) => obj.id === selectedId);
      newObj = {
        type: "group",
        id: id,
        x: obj.x,
        y: obj.y,
        width: obj.width,
        height: obj.height,
        showOutline: true,
        locked: false,
        visible: true,
      };
      newObj.objects = [
        {
          ...obj,
          x: 0,
          y: 0,
          parent: newObj,
        },
      ];
      insertHistory([
        newObj,
        ...objects.filter((obj) => obj.id !== selectedId),
      ]);
      setSelectedId(id);
    }
  }, [selectedId, insertHistory, objects]);

  const addGridLayout = useCallback(() => {
    const id = Date.now().toString();
    const newObject = {
      type: "gridLayout",
      name: `${t("gridLayoutLabel")} (2x2)`,
      id: id,
      x: 0,
      y: 0,
      width: 50.8,
      height: 25.4,
      margin: {
        top: 0,
        left: 0,
      },
      widths: [25.4, 25.4],
      heights: [12.7, 12.7],
      rotation: 0,
      rows: 2,
      cols: 2,
      stroke: "black",
      strokeWidth: 0.2,
      showGrid: true,
      fill: null,
      locked: false,
      visible: true,
      objects: [],
    };
    insertHistory([newObject, ...objects]);
    setSelectedId(id);
  }, [insertHistory, objects, t]);

  const groupingAllowed = useCallback(
    (id) => {
      if (!id) {
        return false;
      }

      const obj = selectObject(id);
      if (!obj) {
        return false;
      } else if (obj.parent) {
        return false;
      } else {
        return true;
      }
    },
    [selectObject]
  );

  useEffect(() => {
    if (selectedId) {
      const selectedObject = selectObject(selectedId);
      setSelectedLock(!!selectedObject?.locked);
    }
  }, [selectedId, objects, selectObject]);

  const updateGroup = (groupObj) => {
    const bbox = calculateBoundingBox(groupObj.objects);
    const group = {
      ...groupObj,
      x: groupObj.x + bbox.x,
      y: groupObj.y + bbox.y,
      width: bbox.width,
      height: bbox.height,
    };
    group.objects = groupObj.objects.map((o) => ({
      ...o,
      x: o.x - bbox.x,
      y: o.y - bbox.y,
      parent: group,
    }));

    if (group.parent && group.parent.type === "group") {
      const parentGroup = selectObject(groupObj.parent.id);
      const index = parentGroup.objects.findIndex((o) => o.id === groupObj.id);
      const parentObjects = [
        ...parentGroup.objects.slice(0, index),
        group,
        ...parentGroup.objects.slice(index + 1),
      ];
      return updateGroup({
        ...parentGroup,
        objects: parentObjects,
      });
    } else {
      return group;
    }
  };

  const [showDeleteNodeWarning, setShowDeleteNodeWarning] = useState(false); //21

  const handleSaveLabel = () => {
    setUpdating(t("creatingLabel", {name: labelName}))
    _controllerRef.current
      .createLabel(
        labelName,
        labelType,
        machineType,
        sheetWidth,
        sheetHeight,
        labelWidth,
        labelHeight,
        rowCount,
        colCount,
        manualLayout.rowGap,
        manualLayout.colGap,
        manualLayout.left,
        manualLayout.top,
        presetId,
        customizedLabel,
        unit,
        unreferencedObjects(objects)
      )
      .then((result) => {
        if (result) {
          onResetCreateLabelStatesToDefaults();
        } else {
          console.log("error: ", result);
        }
        setUpdating(false)
      });
  };

  const handleUpdateLabel = () => {
    setUpdating(t("updatingLabel", {name: labelName}))
    _controllerRef.current
      .updateLabel(
        label,
        labelName,
        labelType,
        machineType,
        sheetWidth,
        sheetHeight,
        labelWidth,
        labelHeight,
        rowCount,
        colCount,
        manualLayout.rowGap,
        manualLayout.colGap,
        manualLayout.left,
        manualLayout.top,
        presetId,
        customizedLabel,
        unit,
        unreferencedObjects(objects)
      )
      .then((result) => {
        if (result) {
          onResetCreateLabelStatesToDefaults();
        } else {
          console.log("error: ", result);
        }        
        setUpdating(false)
      });
  };

  const retrofitNodes = (nodes) => {
    // Retrofit grandfathered labels for KonvaJS label editor

    const retrofitPosition = (node, parent, positions) => {
      node.xPosmm = Number(node.xPosmm) + positions[parent.nodeId].x;
      node.yPosmm = Number(node.yPosmm) + positions[parent.nodeId].y;

      if (parent.parent) {
        retrofitPosition(node, parent.parent, positions);
      }
    };

    const rootNode = nodes.find((node) => node.nodeId === 0);
    setLabelWidth(Number(rootNode.widthmm));
    setLabelHeight(Number(rootNode.heightmm));

    const updateKeys = {
      nodeId: "id",
      xPosmm: "x",
      yPosmm: "y",
      heightmm: "height",
      widthmm: "width",
      borderColor: "stroke",
      borderOn: "strokeWidth",
      font: "fontFamily",
      textAlignment: "align",
      textColor: "fill",
    };

    const updateValues = {
      type: {
        childRectangle: "rect",
        rectangle: "rect",
      },
      strokeWidth: {
        true: 0.2,
      },
      symbology: {
        Code128A: "code128",
        Code128B: "code128",
        Code39: "code39",
        DataMatrix: "datamatrix",
        QRBarcode: "qrcode",
      },
    };

    const dropKeys = [
      "childNodes",
      "parent",
      "bold",
      "italic",
      "wordWrap",
      "value",
      "mapping",
    ];
    const textualValues = ["id"];
    const numericValues = [
      "x",
      "y",
      "height",
      "width",
      "strokeWidth",
      "fontSize",
    ];

    const positions = nodes.reduce((acc, node) => {
      acc[node.nodeId] = {
        x: Number(node.xPosmm) + Number(node?.padding?.left || 0),
        y: Number(node.yPosmm) + Number(node?.padding?.top || 0),
      };
      return acc;
    }, {});

    const updatedNodes = nodes
      .filter(
        (node) =>
          node.nodeId !== 0 &&
          node.type !== "repeater" && 
          !(node.type === "childRectangle" && node.borderOn === false) 
      )
      .map((node) => {
        const updatedNode = {};

        retrofitPosition(node, node.parent, positions);

        for (const key in node) {
          if (key in updateKeys) {
            updatedNode[updateKeys[key]] = node[key];
          } else if (!dropKeys.includes(key)) {
            updatedNode[key] = node[key];
          }
        }

        for (const key in updateValues) {
          if (key in updatedNode) {
            const currentValue = updatedNode[key];
            const newValue = updateValues[key][currentValue];
            if (newValue !== undefined) {
              updatedNode[key] = newValue;
            }
          }
        }

        for (const key of textualValues) {
          if (key in updatedNode) {
            updatedNode[key] = updatedNode[key].toString();
          }
        }

        for (const key of numericValues) {
          if (key in updatedNode) {
            updatedNode[key] = Number(updatedNode[key]);
          }
        }

        if (updatedNode.type === "text") {
          if (node.bold && node.italic) {
            updatedNode.fontStyle = "bold italic";
          } else if (node.bold) {
            updatedNode.fontStyle = "bold";
          } else if (node.italic) {
            updatedNode.fontStyle = "italic";
          } else {
            updatedNode.fontStyle = "normal";
          }

          if (node.wordWrap) {
            updatedNode.wrap = "word";
          } else {
            updatedNode.wrap = "none";
          }

          updatedNode.mappingName = node.mapping.friendlyName;
          updatedNode.mappingPath = node.mapping.path;
        } else if (updatedNode.type === "image") {
          updatedNode.width = pxToMm(Number(node.width));
          updatedNode.height = pxToMm(Number(node.height));
        } else if (updatedNode.type === "barcode") {
          updatedNode.defaultValues = {
            code128: node.defaultValueCode128B,
            code39: node.defaultValueCode39,
            datamatrix: node.defaultValueDataMatrix,
            qrcode: node.defaultValueQRBarcode,
          };

          updatedNode.mappingName = node.mapping.friendlyName;
          updatedNode.mappingPath = node.mapping.path;
        }

        updatedNode.locked = false;
        updatedNode.visible = true;
        updatedNode.parentId = null;
        return updatedNode;
      });

    retrofitted.current = true;
    return updatedNodes;
  };

  useEffect(() => {
    // Insert label's template objects on first load 

    if (!preloaded.current && nodesArrayForUpdate?.length) {
      if (
        nodesArrayForUpdate[0].hasOwnProperty("xPosmm") &&
        !retrofitted.current
      ) {
        insertHistory(retrofitNodes(nodesArrayForUpdate));
      } else {
        insertHistory(rereferencedObjects(nodesArrayForUpdate));
      }
    }
    preloaded.current = true;
  }, [nodesArrayForUpdate, insertHistory, preloaded]);

  const handleHotkeys = useCallback(
    (e) => {
      if (
        selectedId &&
        !selectedLock &&
        (e.keyCode === 46 || e.keyCode === 8) &&
        e.target.tagName !== "INPUT"
      ) {
        setShowDeleteNodeWarning(true);
      } else if (e.ctrlKey && e.keyCode === 90) {
        if (positionRef.current < history.length - 1) {
          selectHistory(positionRef.current + 1);
        }
      } else if (e.ctrlKey && e.keyCode === 89) {
        if (positionRef.current) {
          selectHistory(positionRef.current - 1);
        }
      } else if (e.ctrlKey && e.keyCode === 67 && selectedId) {
        copyObject(selectedId);
      } else if (e.ctrlKey && e.keyCode === 86 && clipboard) {
        pasteObject();
      }
    },
    [
      selectedId,
      selectedLock,
      selectHistory,
      history,
      clipboard,
      copyObject,
      pasteObject,
    ]
  );

  const handleDeleteNode = (deleteId) => {
    deleteObject(deleteId);
  };

  const handleStageContainerClick = (e) => {
    setSelectedId(null);
  };

  const handleResizeStart = useCallback(
    (e) => {
      const name = e.target.getAttribute("name");
      setResizing({
        side: name,
        startX: e.clientX,
        startWidth: name === "left" ? leftPanelWidth : rightPanelWidth,
      });
    },
    [leftPanelWidth, rightPanelWidth]
  );

  const handleResizeEnd = useCallback(() => {
    setResizing(false);
  }, []);

  const handleResizeMove = useCallback(
    (e) => {
      if (resizing) {
        const delta =
          resizing.side === "left"
            ? e.clientX - resizing.startX
            : resizing.startX - e.clientX;
        const newWidth = resizing.startWidth + delta;

        if (resizing.side === "left") {
          setLeftPanelWidth(Math.max(150, Math.min(newWidth, 800)));
        } else {
          setRightPanelWidth(Math.max(150, Math.min(newWidth, 800)));
        }
      }
    },
    [resizing]
  );

  const handlePrevious = () => {    
    onHideLabelCanvas();
  };

  useEffect(() => {
    if (resizing) {
      document.addEventListener("mousemove", handleResizeMove);
      document.addEventListener("mouseup", handleResizeEnd);
    }

    return () => {
      document.removeEventListener("mousemove", handleResizeMove);
      document.removeEventListener("mouseup", handleResizeEnd);
    };
  }, [resizing, handleResizeMove, handleResizeEnd]);

  useEffect(() => {
    const calculateArea = () => {
      const availableWidth = window.innerWidth - 280; // navbar 120, home padding 60*2, buffer 40
      const availableHeight = window.innerHeight - 222; // home padding 40+60, buttons 48+34, buffer 40

      setAreaWidth(availableWidth);
      setAreaHeight(availableHeight);
    };

    const handleZoom = (e) => {
      if (e.ctrlKey && e.target.closest("#stageContainer")) {
        e.preventDefault();

        const direction = e.deltaY < 0 ? 1 : -1;

        const setCurrentZoom = viewerMode ? setViewerZoom : setZoom;

        setCurrentZoom((prevZoom) => {
          const newZoom = prevZoom + direction * 10;
          if (newZoom < 10) {
            return 10;
          } else if (newZoom > 300) {
            return 300;
          } else {
            return newZoom;
          }
        });
      }
    };

    calculateArea();
    window.addEventListener("resize", calculateArea);
    window.addEventListener("keydown", handleHotkeys);
    window.addEventListener("wheel", handleZoom, { passive: false });

    return () => {
      window.removeEventListener("resize", calculateArea);
      window.removeEventListener("keydown", handleHotkeys);
      window.removeEventListener("wheel", handleZoom);
    };
  }, [handleHotkeys, viewerMode]);

  const DeleteWarning = (
    <ModalBackdrop
      width="100%"
      height="100%"
      top="0"
      left="0"
      padding="0"
      showButton={false}
      backgroundColor="#98a9bc4d"
      borderRadius="0"
    >
      <div className={styles.LabelEditor__deleteWarningModal}>
        <p>{t("deleteWarning")}</p>
        <div className={styles.LabelEditor__ModalButtonsGroup}>
          <Button
            labelName={t("cancelButtonLabel")}
            minWidth={"123px"}
            isPrimary={false}
            onClick={() => setShowDeleteNodeWarning(false)}
          />
          <Spacer space={20} unit={"px"} />
          <Button
            labelName={t("deleteButtonLabel")}
            minWidth={"123px"}
            isPrimary={true}
            onClick={() => {
              setShowDeleteNodeWarning(false);
              handleDeleteNode(selectedId);
            }}
          />
        </div>
      </div>
    </ModalBackdrop>
  );

  const Components = () => (
    <div className={styles.LabelEditor__objectExplorer}>
      <header>
        <h2
          className={`
            ${styles.LabelEditor__row}
            ${styles.LabelEditor__clickable} 
            ${!showComponents ? styles.collapsed : ""}
            ${styles.centered}
            ${styles.spaced}
          `}
          onClick={() => setShowComponents((prev) => !prev)}
        >
          <Tooltip
            title={
              showComponents ? t("collapseTooltip") : t("uncollapseTooltip")
            }
            color={"var(--green)"}
            mouseLeaveDelay={0}
          >
            {showComponents ? <CheveronDownSVG /> : <CheveronRightSVG />}
          </Tooltip>
          <Tooltip
            title={
              showComponents ? t("collapseTooltip") : t("uncollapseTooltip")
            }
            color={"var(--green)"}
            mouseLeaveDelay={0}
          >
            <div>{t("explorerLabel")}</div>
          </Tooltip>
        </h2>
        {showComponents &&
          [
            {
              id: "rect",
              SVGComponent: RectSVG,
              label: t("rectLabel"),
              tooltip: t("rectTooltip"),
              addObject: addRect,
            },
            {
              id: "barcode",
              SVGComponent: BarcodeSVG,
              label: t("barcodeLabel"),
              tooltip: t("barcodeTooltip"),
              addObject: addBarcode,
            },
            {
              id: "text",
              SVGComponent: TextSVG,
              label: t("textLabel"),
              tooltip: t("textTooltip"),
              addObject: addText,
            },
            {
              id: "image",
              SVGComponent: ImageSVG,
              label: t("imageLabel"),
              tooltip: t("imageTooltip"),
              addObject: addImage,
            },
            {
              id: "gridLayout",
              SVGComponent: GridLayoutSVG,
              label: t("gridLayoutLabel"),
              tooltip: t("gridlayoutTooltip"),
              addObject: addGridLayout,
            },
          ].map(({ id, SVGComponent, label, tooltip, addObject }) => (
            <div key={id} className={styles.LabelEditor__objectExplorerGroup}>
              <div
                id={id}
                className={`
                ${styles.LabelEditor__svgContainer} 
                ${!viewerMode ? styles.LabelEditor__clickable : ""}
                ${
                  id === "text"
                    ? styles.LabelEditor__objectExplorerTextSVGContainer
                    : id === "image"
                    ? styles.LabelEditor__objectExplorerImageSVGContainer
                    : styles.LabelEditor__objectExplorerSVGContainer
                }`}
                draggable={true}
                onClick={!viewerMode ? () => addObject() : undefined}
                onDragEnd={(e) => {
                  e.preventDefault();
                  const stage = stageRef.current.getStage();
                  const stageBox = stage.container().getBoundingClientRect();

                  const x = e.clientX - stageBox.left;
                  const y = e.clientY - stageBox.top;

                  if (
                    x > 0 &&
                    y > 0 &&
                    x < stageBox.width &&
                    y < stageBox.height
                  ) {
                    addObject(x, y);
                  }
                }}
              >
                <div className={styles.LabelEditor__objectExplorerSVGContainer}>
                  <Tooltip
                    title={tooltip}
                    color={"var(--green)"}
                    mouseLeaveDelay={0}
                  >
                    <SVGComponent />
                  </Tooltip>
                </div>
              </div>
              <div
                className={`
                ${styles.LabelEditor__objectExplorerTypeName} 
                ${
                  !viewerMode
                    ? styles.LabelEditor__clickable
                    : styles.LabelEditor__disabled
                }
              `}
                onClick={!viewerMode ? () => addObject() : undefined}
              >
                <Tooltip
                  title={tooltip}
                  color={"var(--green)"}
                  mouseLeaveDelay={0}
                >
                  <div>{label}</div>
                </Tooltip>
              </div>
            </div>
          ))}
      </header>
    </div>
  );

  const Layers = () => (
    <div className={styles.LabelEditor__layers}>
      <header>
        <h2>{t("layersLabel")}</h2>
        <div className={styles.LabelEditor__layersButtons}>
          <div
            className={
              positionRef.current < history.length - 1
                ? styles.LabelEditor__layersButton
                : ""
            }
            onClick={() => {
              if (positionRef.current < history.length - 1)
                selectHistory(positionRef.current + 1);
            }}
          >
            <Tooltip 
              title={t("undoTooltip")} 
              color={"var(--green)"} 
              mouseLeaveDelay={0}
            >
              <UndoSVG
                width={"16px"}
                height={"16px"}
                color={
                  positionRef.current < history.length - 1 ? "black" : "gray"
                }
              />
            </Tooltip>
          </div>
          <div
            className={
              positionRef.current ? styles.LabelEditor__layersButton : ""
            }
            onClick={() => {
              if (positionRef.current) selectHistory(positionRef.current - 1);
            }}
          >
            <Tooltip 
              title={t("redoTooltip")} 
              color={"var(--green)"} 
              mouseLeaveDelay={0}
            >
              <RedoSVG
                width={"16px"}
                height={"16px"}
                color={positionRef.current ? "black" : "gray"}
              />
            </Tooltip>
          </div>
          {!viewerMode && (
            <>
              <div
                className={selectedId ? styles.LabelEditor__layersButton : ""}
                onClick={() => {
                  if (selectedId) duplicateObject(selectedId);
                }}
              >
                <Tooltip 
                  title={t("duplicateTooltip")} 
                  color={"var(--green)"} 
                  mouseLeaveDelay={0}
                >
                  <DuplicateSVG
                    width={"16px"}
                    height={"16px"}
                    color={selectedId ? "black" : "gray"}
                  />
                </Tooltip>
              </div>                
              <div
                className={
                  groupingAllowed(selectedId)
                    ? styles.LabelEditor__layersButton
                    : ""
                }
                onClick={() => {
                  if (groupingAllowed(selectedId)) addGroup();
                }}
              >
                <Tooltip
                  title={t("groupTooltip")} 
                  color={"var(--green)"} 
                  mouseLeaveDelay={0}
                >
                  <GroupSVG
                    width={"16px"}
                    height={"16px"}
                    color={groupingAllowed(selectedId) ? "black" : "gray"}
                  />
                </Tooltip>
              </div>
            </>
          )}
        </div>
      </header>

      <SortableLayerItems
        objects={objects}
        insertHistory={insertHistory}
        updateObject={updateObject}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        updateGroup={updateGroup}
        selectObject={selectObject}
        viewerMode={viewerMode}
        layersScrollPosition={layersScrollPosition}
        setLayersScrollPosition={setLayersScrollPosition}
      />
    </div>
  );

  const LayersPanel = () => {
    return (
      <div
        className={styles.LabelEditor__objectsPanel}
        style={{
          height: areaHeight,
          width: leftPanelWidth,
          minWidth: leftPanelWidth,
          zIndex: 2,
          backgroundColor: "white",
        }}
      >
        <Layers />
        <Components />
      </div>
    );
  };

  const PropertiesPanel = () => {
    const selectedObject = selectObject(selectedId);

    const propertiesTitle = selectedObject
      ? `${t("propertiesLabel")} - ${t(selectedObject.type + "Label")}`
      : t("labelPropertiesLabel");

    const renderProperties = () => {
      if (!selectedObject)
        return (
          <LabelProperties
            unit={unit}
            width={labelWidth}
            setWidth={setLabelWidth}
            height={labelHeight}
            setHeight={setLabelHeight}
          />
        );

      switch (selectedObject.type) {
        case "rect":
          return (
            <RectProperties
              unit={unit}
              selectedObject={selectedObject}
              updateObject={updateObject}
            />
          );
        case "text":
          return (
            <TextProperties
              unit={unit}
              selectedObject={selectedObject}
              updateObject={updateObject}
              labelType={labelType}
            />
          );
        case "image":
          return (
            <ImageProperties
              unit={unit}
              selectedObject={selectedObject}
              updateObject={updateObject}
            />
          );
        case "barcode":
          return (
            <BarcodeProperties
              unit={unit}
              selectedObject={selectedObject}
              updateObject={updateObject}
              labelType={labelType}
            />
          );
        case "group":
          return (
            <GroupProperties
              unit={unit}
              selectedObject={selectedObject}
              updateObject={updateObject}
            />
          );
        case "gridLayout":
          return (
            <GridLayoutProperties
              unit={unit}
              selectedObject={selectedObject}
              updateObject={updateObject}
            />
          );
        default:
          return null;
      }
    };

    return (
      <div
        className={styles.LabelEditor__propertiesPanel}
        style={{
          height: areaHeight,
          width: rightPanelWidth,
          minWidth: rightPanelWidth,
          zIndex: 2,
          backgroundColor: "white",
        }}
      >
        {!viewerMode ? (
          <div className={styles.LabelEditor__objectProperties}>
            <header className={styles.LabelEditor__propertiesHeader}>
              <h2>{propertiesTitle}</h2>
              {selectedObject && (
                <div
                  className={`${styles.LabelEditor__trashIconContainer} ${
                    selectedObject.locked ? styles.disabled : ""
                  }`}
                  {...(selectedObject.locked
                    ? {}
                    : { onClick: () => setShowDeleteNodeWarning(true) })}
                >
                  <TrashIcon />
                </div>
              )}
            </header>
            {renderProperties()}
          </div>
        ) : (
          <div className={styles.LabelEditor__objectProperties}>
            <header className={styles.LabelEditor__propertiesHeader}>
              <h2>{t("printProperties")}</h2>
            </header>
            <SheetProperties
              _controllerRef={_controllerRef}
              unit={unit}
              applyPreset={applyPreset}
              labelPresets={labelPresets}
              setLabelPresets={setLabelPresets}
              presetId={presetId}
              setPresetId={setPresetId}
              presetName={presetName}
              setPresetName={setPresetName}
              labelWidth={labelWidth}
              setLabelWidth={setLabelWidth}
              labelHeight={labelHeight}
              setLabelHeight={setLabelHeight}
              sheetWidth={sheetWidth}
              setSheetWidth={setSheetWidth}
              sheetHeight={sheetHeight}
              setSheetHeight={setSheetHeight}
              rowCount={rowCount}
              setRowCount={setRowCount}
              colCount={colCount}
              setColCount={setColCount}
              previewOutline={previewOutline}
              setPreviewOutline={setPreviewOutline}
              autoLayout={autoLayout}
              setAutoLayout={setAutoLayout}
              manualLayout={manualLayout}
              setManualLayout={setManualLayout}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {updating && <WaitIndicator message={updating} />}
      <div
        className={`${styles.LabelEditor__labelAndButtonsContainer} ${styles.LabelEditor__preventSelect}`}
      >
        <div className={styles.LabelEditor__groupContainer}>
          {showDeleteNodeWarning && DeleteWarning}

          <div className={styles.LabelEditor__labelEditorContainer}>
            {LayersPanel()}

            <Tooltip 
              title={resizing ? "" : t("resizeTooltip")} 
              color={"var(--green)"} 
              mouseLeaveDelay={0} 
              placement={"right"}
            >
              <div
                name="left"
                className={styles.LabelEditor__resizer}
                style={{
                  width: "4px",
                  height: areaHeight,
                  cursor: "col-resize",
                  backgroundColor: "black",
                  userSelect: "none",
                }}
                onMouseDown={handleResizeStart}
              />
            </Tooltip>

            <div
              id="stageContainerWrapper"
              style={{
                display: "flex",
                flexDirection: "column",
                width: areaWidth - leftPanelWidth - rightPanelWidth,
                height: areaHeight,
              }}
            >
              <div
                id="stageContainer"
                className={styles.LabelEditor__stageContainer}
                onClick={handleStageContainerClick}
                style={{
                  width: areaWidth - leftPanelWidth - rightPanelWidth,
                  height: areaHeight - 50,
                }}
              >
                <div id="stage" className={styles.LabelEditor__stage}>
                  {!viewerMode && (
                    <LabelEditorStage
                      stageRef={stageRef}
                      width={labelWidth}
                      height={labelHeight}
                      zoom={zoom}
                      objects={objects}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      selectObject={selectObject}
                      updateObject={updateObject}
                      transformerLock={selectedLock}
                      transformerReset={transformerReset}
                      setTransformerReset={setTransformerReset}
                      updateGroup={updateGroup}
                    />
                  )}

                  {viewerMode && (
                    <LabelViewerStage
                      stageRef={stageRef}
                      canisterObjects={new Array(rowCount * colCount).fill(
                        objects
                      )}
                      page={0}
                      zoom={viewerZoom}
                      sheetWidth={sheetWidth}
                      sheetHeight={sheetHeight}
                      labelWidth={labelWidth}
                      labelHeight={labelHeight}
                      rowCount={rowCount}
                      colCount={colCount}
                      autoLayout={autoLayout}
                      manualLayout={manualLayout}
                      previewOutline={previewOutline}
                      previewPosition={false}
                    />
                  )}
                </div>
              </div>

              <div
                className={styles.Properties__spacedRow}
                style={{
                  width: areaWidth - leftPanelWidth - rightPanelWidth,
                  height: "50px",
                  border: "1px solid black",
                }}
              >
                <AreaProperties
                  zoom={viewerMode ? viewerZoom : zoom}
                  setZoom={viewerMode ? setViewerZoom : setZoom}
                  viewerMode={viewerMode}
                  setViewerMode={setViewerMode}
                  unit={unit}
                  setUnit={setUnit}
                />
              </div>
            </div>

            <Tooltip 
              title={resizing ? "" : t("resizeTooltip")} 
              color={"var(--green)"} 
              mouseLeaveDelay={0} 
              placement={"left"}
            >
              <div
                name="right"
                className={styles.LabelEditor__resizer}
                style={{
                  width: "4px",
                  height: areaHeight,
                  cursor: "col-resize",
                  backgroundColor: "black",
                  userSelect: "none",
                }}
                onMouseDown={handleResizeStart}
              />
            </Tooltip>

            {PropertiesPanel()}
          </div>
        </div>
        <div className={styles.LabelEditor__buttonsContainer}>
          <Button
            labelName={t("prevButtonLabel")}
            minWidth={"123px"}
            isPrimary={false}
            onClick={() => handlePrevious()}
          />
          <Spacer space={20} unit={"px"} />
          <div
            onClick={
              nodesArrayForUpdate?.length 
                ? handleUpdateLabel 
                : handleSaveLabel
            }
          >
            <Button
              labelName={
                nodesArrayForUpdate?.length
                  ? t("updateButtonLabel")
                  : t("saveButtonLabel")
              }
              isPrimary={true}
              minWidth={"213px"}
              isDisabled={objects.length === 0}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LabelEditor;
