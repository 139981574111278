import { Col, Input, Modal, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ClientFDBService from "../../services/ClientFDBService";

const { Search } = Input;

const ProductsCheckForUpdatesModal = ({
  user,
  open,
  onCancel,
  onOk,
  onError,
}) => {
  const { t } = useTranslation("products");

  const [fdbModifications, setFdbModifications] = useState([]);
  const [tableDataSource, setTableDataSource] = useState([]);
  const [selectedFdbModificationsKey, setSelectedFdbModificationsKey] =
    useState([]);
  const [fdbModificationFilterText, setFdbModificationFilterText] =
    useState("");
  const [fdbModificationsLoading, setFdbModificationsLoading] = useState(false);

  const handleFdbModificationFilterTextChange = () => {
    if (!fdbModificationFilterText) {
      setTableDataSource(fdbModifications);
    } else {
      const temp = fdbModifications.filter((x) =>
        x.packagedDrugDesc
          .toLowerCase()
          .includes(fdbModificationFilterText.toLowerCase())
      );
      setTableDataSource(temp);
    }
  };

  const getFdbModifications = async () => {
    try {
      setFdbModificationsLoading(true);
      const result = await ClientFDBService.getFdbModifications(user);
      setFdbModifications(result.modifications);
      setTableDataSource(result.modifications);
    } catch (error) {
      onError(error);
    } finally {
      setFdbModificationsLoading(false);
    }
  };

  useEffect(async () => {
    if (open) {
      await getFdbModifications();
    } else {
      setTableDataSource([]);
    }
  }, [open]);

  return (
    <Modal
        width={1024}
        height={768}
        title={"Check for updates"}
        open={open}
        onCancel={onCancel}
        onOk={onOk}
      >
        <Row>
          <Col span={24}>
            <Search
              placeholder={"Search"}
              value={fdbModificationFilterText}
              allowClear={true}
              onChange={async (e) => {
                const value = e.target.value;
                setFdbModificationFilterText(value);
                handleFdbModificationFilterTextChange();
              }}
            />
          </Col>
          <Col span={24}>
            <Table
              columns={[
                {
                  title: "Drug ID",
                  render: (p) => p.packagedDrugID,
                  ellipsis: true,
                },
                {
                  title: "Drug name",
                  render: (p) => p.packagedDrugDesc,
                  ellipsis: true,
                },
                {
                  title: "Description of updated information",
                  render: (p) => "abc123",
                  ellipsis: true,
                },
              ]}
              rowKey={(record) => record.packagedDrugID}
              dataSource={tableDataSource}
              pagination={false}
              loading={fdbModificationsLoading}
              rowSelection={{
                selectedRowKeys: selectedFdbModificationsKey,
                onChange: (newSelectedRowKeys) => {
                  setSelectedFdbModificationsKey(newSelectedRowKeys);
                },
              }}
              scroll={{ y: 500 }}
            />
          </Col>
        </Row>
      </Modal>
  );
};

export default ProductsCheckForUpdatesModal;
