import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { useMediaQuery } from "react-responsive";
import StockLocationService from "../../../services/StockLocationService";
import TruPakDashboardController from "../TruPakDashboard/TruPakDashboardController";
import GetAlarmsGraph from "./getGraphsLogic/GetAlarmsGraph";
import GetBatchesPerDayGraph from "./getGraphsLogic/GetBatchesPerDayGraph";
import GetCanisterTrayRatioGraph from "./getGraphsLogic/GetCanisterTrayRatioGraph";
import GetInventoryGraph from "./getGraphsLogic/GetInventoryGraph";
import GetPouchesPerHourGraph from "./getGraphsLogic/GetPouchesPerHourGraph";
import { Rings } from "react-loader-spinner";
import TruPakAlarmsTable from "./SubViews/TruPakAlarmsTable";
import TruPakInventoryTable from "./SubViews/TruPakInventoryTable";
import MedicationsDispensed from "./SubViews/MedicationsDispensed/MedicationsDispensed";
import { ScreenContext } from "../../../contexts/ScreenContext";
import Checkbox from "../../../components/Checkbox";
import backIcon from "../../../assets/back_icon.svg";
import styles from "./TruPakDashboard.module.css";
import stylesMobile from "./MobileViews/TruPakDashboardMobileView.module.css";
import { useTranslation } from "react-i18next";

const soonToExpireThresholdForInventoryGraph = 90;

const TruPakDashboard = ({ props }) => {
  const {
    user,
    sites,
    windowHeight,
    viewWidth,
    showDropdowns,
    onShowDropdowns,
  } = props;

  const { t } = useTranslation("trupakDashboard");

  const [siteSelections, setSiteSelections] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [dispensingDevices, setDispensingDevices] = useState([]);
  const [deviceSelections, setDeviceSelections] = useState([]);
  const [deviceSelections2, setDeviceSelections2] = useState({});
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedGraph, setSelectedGraph] = useState(null);
  const [SelectedGraphView, setSelectedGraphView] = useState(null);
  const [inventoryTableData, setInventoryTableData] = useState([]);
  const [alarmsStartDate, setAlarmsStartDate] = useState(new Date());
  const [alarmsStopDate] = useState(new Date());

  const [canisterTrayRatioStartDate, setCanisterTrayRatioStartDate] =
    useState(null);
  const [canisterTrayRatioStopDate, setCanisterTrayRatioStopDate] =
    useState(null);

  const handleCanisterTrayRatioStartDate = useCallback(function (startDate) {
    setCanisterTrayRatioStartDate(startDate);
  }, []);
  const handleCanisterTrayRatioStopDate = useCallback(function (stopDate) {
    setCanisterTrayRatioStopDate(stopDate);
  }, []);

  const [batches, setBatches] = useState([]);

  const [showMedicationsDispensed, setShowMedicationsDispensed] =
    useState(false);

  const [
    showCanisterMedicationsDispensed,
    setShowCanisterMedicationsDispensed,
  ] = useState(false);
  const [showTrayMedicationsDispensed, setShowTrayMedicationsDispensed] =
    useState(false);

  const handleShowCanisterMedicationsDispensed = useCallback(function (
    boolVal
  ) {
    setShowCanisterMedicationsDispensed(boolVal);
  },
  []);
  const handleShowTrayMedicationsDispensed = useCallback(function (boolVal) {
    setShowTrayMedicationsDispensed(boolVal);
  }, []);

  const viewHeight = (+windowHeight - 200).toString() + "px";

  const _controllerRef = useRef(TruPakDashboardController);

  const { smallScreen, mediumScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const mdScreen = useMediaQuery(mediumScreen);

  useEffect(() => {
    // 7 days total
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 6); // 7 days total

    setAlarmsStartDate(startDate);
  }, []);

  useEffect(() => {
    selectedGraph ? onShowDropdowns(false) : onShowDropdowns(true);
  }, [selectedGraph]);

  useEffect(() => {
    let tempSiteSelections = [];
    sites.forEach(() => {
      tempSiteSelections.push(false);
    });
    setSiteSelections(tempSiteSelections);
  }, [sites]);

  useEffect(() => {
    // update selectedDevices
    let tempSelectedDevices = [];
    dispensingDevices.forEach((device) => {
      if (deviceSelections2[device.name]) {
        tempSelectedDevices.push(device);
      }
    });
    setSelectedDevices(tempSelectedDevices);
  }, [dispensingDevices, deviceSelections2]);

  useEffect(() => {
    // set deviceSelection to false if its associated site is no longer selected
    let tempDeviceSelections2 = { ...deviceSelections2 };
    dispensingDevices.forEach((device, deviceIndex) => {
      const deviceSite = device.site;
      const siteIndex = sites.findIndex((site) => site._id === deviceSite._id);

      if (siteIndex !== -1) {
        if (!siteSelections[siteIndex]) {
          tempDeviceSelections2[device.name] = false;
        }
      }
    });
    setDeviceSelections2(tempDeviceSelections2);
  }, [sites, siteSelections]);

  useEffect(() => {
    if (siteSelections.length && siteSelections.length === sites.length) {
      let tempSelectedSites = sites.filter(
        (site, index) => siteSelections[index]
      );
      setSelectedSites(tempSelectedSites);
    }
  }, [sites, siteSelections]);

  useEffect(() => {
    if (selectedSites?.length) {
      let tempFilteredDevices = [];
      async function updateDevicesTruPak() {
        for (let i = 0; i < selectedSites.length; i++) {
          await StockLocationService.getDispensableLocations(
            user,
            selectedSites[i]
          )
            .then((stockLocations) => {
              const filteredDevices = stockLocations.filter(
                (device) => device.type.type === "POUCH_PACKAGER"
              );
              tempFilteredDevices = [
                ...tempFilteredDevices,
                ...filteredDevices,
              ];
            })
            .catch((error) => {
              console.log(error);
            });
        }
        setDispensingDevices(tempFilteredDevices);
      }
      updateDevicesTruPak();
    } else if (!selectedSites?.length) {
      setDispensingDevices([]);
    }
  }, [user, selectedSites]);

  useEffect(() => {
    if (!dispensingDevices.length || selectedDevices.length === 0) {
      setInventoryTableData([]);
    }
  }, [dispensingDevices, selectedDevices]);

  useEffect(() => {
    if (!showCanisterMedicationsDispensed && !showTrayMedicationsDispensed) {
      onShowDropdowns(true);
    }
  }, [showCanisterMedicationsDispensed, showTrayMedicationsDispensed]);

  const handleGraphClick = (graphId) => {
    setSelectedGraph(graphId);
  };

  const loader = (
    <div
      className={
        smScreen
          ? stylesMobile.TruPakDashboardMobile__loader
          : styles.TruPakDashboard__loader
      }
    >
      <Rings color="#089BAB" height={110} width={110} />
      <p>{t("fetching")}</p>
    </div>
  );

  const handleSiteCheckboxClick = (index) => {
    let tempSiteSelections = [...siteSelections];
    tempSiteSelections[index] = !siteSelections[index];
    setSiteSelections(tempSiteSelections);
  };

  const sitesSelectionList = sites.map((site, siteIndex) => {
    return (
      <li key={site._id} className={styles.TruPakDashboard__siteListItem}>
        <Checkbox
          labelName={site.name}
          isOn={siteSelections[siteIndex]}
          onCheck={() => handleSiteCheckboxClick(siteIndex)}
        />
      </li>
    );
  });

  const handleDeviceCheckboxClick = (index, deviceName) => {
    let tempDeviceSelections = [...deviceSelections];
    tempDeviceSelections[index] = !deviceSelections[index];
    setDeviceSelections(tempDeviceSelections);

    // new
    let tempDeviceSelections2 = { ...deviceSelections2 };
    tempDeviceSelections2[deviceName] = !tempDeviceSelections2[deviceName];
    setDeviceSelections2(tempDeviceSelections2);
  };

  const devicesSelectionList = dispensingDevices.map((device, deviceIndex) => {
    const deviceName = device.name;
    return (
      <li key={device._id} className={styles.TruPakDashboard__deviceListItem}>
        <Checkbox
          labelName={deviceName}
          isOn={deviceSelections2[deviceName]}
          onCheck={() => handleDeviceCheckboxClick(deviceIndex, deviceName)}
        />
      </li>
    );
  });

  let numSitesPerRow = 1;
  if (smScreen) {
    numSitesPerRow = 2;
  } else if (mdScreen) {
    numSitesPerRow = 3;
  } else {
    numSitesPerRow = 5;
  }

  const numFullSiteRows = Math.floor(sites.length / numSitesPerRow);
  const numSitesLastRow = sites.length % numSitesPerRow;
  let SiteRows = [];

  if (sites.length > numSitesPerRow) {
    for (let row = 0; row < numFullSiteRows; row++) {
      SiteRows.push(
        <ul
          className={styles.TruPakDashboard__sitesSelectionList}
          key={`row-${row}`}
        >
          {sitesSelectionList.slice(
            numSitesPerRow * row,
            numSitesPerRow * row + numSitesPerRow
          )}
        </ul>
      );
    }
    SiteRows.push(
      <ul
        className={styles.TruPakDashboard__sitesSelectionList}
        key={`row-${numFullSiteRows}`}
      >
        {sitesSelectionList.slice(
          numSitesPerRow * numFullSiteRows,
          numSitesPerRow * numFullSiteRows + numSitesLastRow + 1
        )}
      </ul>
    );
  } else {
    SiteRows.push(
      <ul className={styles.TruPakDashboard__sitesSelectionList} key={"sites"}>
        {sitesSelectionList}
      </ul>
    );
  }

  let numDevicesPerRow = 1;
  if (smScreen) {
    numDevicesPerRow = 2;
  } else if (mdScreen) {
    numDevicesPerRow = 3;
  } else {
    numDevicesPerRow = 5;
  }

  const numFullDevicesRows = Math.floor(sites.length / numDevicesPerRow);
  const numDevicesLastRow = sites.length % numDevicesPerRow;
  let DeviceRows = [];

  if (dispensingDevices.length > numDevicesPerRow) {
    for (let row = 0; row < numFullDevicesRows; row++) {
      DeviceRows.push(
        <ul
          className={styles.TruPakDashboard__devicesSelectionList}
          key={`row-${row}`}
        >
          {devicesSelectionList.slice(
            numDevicesPerRow * row,
            numDevicesPerRow * row + numDevicesPerRow
          )}
        </ul>
      );
    }
    DeviceRows.push(
      <ul
        className={styles.TruPakDashboard__devicesSelectionList}
        key={`row-${numFullDevicesRows}`}
      >
        {devicesSelectionList.slice(
          numDevicesPerRow * numFullDevicesRows,
          numDevicesPerRow * numFullDevicesRows + numDevicesLastRow + 1
        )}
      </ul>
    );
  } else {
    DeviceRows.push(
      <ul
        className={styles.TruPakDashboard__devicesSelectionList}
        key={"devices"}
      >
        {devicesSelectionList}
      </ul>
    );
  }

  useEffect(() => {
    switch (selectedGraph) {
      case 3:
        setSelectedGraphView(
          <TruPakInventoryTable
            goBackHandler={goBackHandler}
            selectedGraph={selectedGraph}
            windowHeight={windowHeight}
            inventoryTableData={inventoryTableData}
          />
        );
        break;
      case 4:
        setSelectedGraphView(
          <TruPakAlarmsTable
            goBackHandler={goBackHandler}
            selectedDevices={selectedDevices}
            deviceName={selectedGraph?.deviceName}
            _controllerRef={_controllerRef}
            user={user}
            startDate={alarmsStartDate}
            stopDate={alarmsStopDate}
            windowHeight={windowHeight}
          />
        );
        break;
      default:
        setSelectedGraphView(null);
    }
  }, [
    selectedGraph,
    windowHeight,
    inventoryTableData,
    selectedDevices,
    user,
    alarmsStartDate,
    alarmsStopDate,
  ]);

  const goBackHandler = () => {
    setSelectedGraph(null);
  };

  const handleInventoryTableData = (tableData) => {
    setInventoryTableData(tableData);
  };

  const handleBatches = (arr) => {
    setBatches(arr);
  };

  const handleShowMedicationsDispensed = useCallback(function (boolVal) {
    setShowMedicationsDispensed(boolVal);
  }, []);

  return (
    <div className={styles.TruPakDashboard__pageContainer}>
      <div
        className={
          showMedicationsDispensed
            ? styles.TruPakDashboard__noDisplay
            : styles.TruPakDashboard__sitesAndDevicesContainer
        }
      >
        <div className={styles.TruPakDashboard__sitesSelectionListContainer}>
          <p>{t("sitesLabel")}</p>
          <div>{SiteRows}</div>
        </div>
        <div className={styles.TruPakDashboard__devicesSelectionListContainer}>
          <p>{t("devicesLabel")}</p>
          <div>{DeviceRows}</div>
        </div>
      </div>
      {!selectedGraph && (
        <div
          style={{ height: viewHeight }}
          className={
            showMedicationsDispensed
              ? styles.TruPakDashboard__noDisplay
              : styles.TruPakDashboard__viewContainer
          }
        >
          <div
            style={{ height: viewHeight }}
            className={styles.TruPakDashboard__graphsGroup}
          >
            {/* <GetPouchesPerHourGraph
              user={user}
              dispensingDevices={dispensingDevices}
              selectedDevices={selectedDevices}
              selectedSites={selectedSites}
              viewWidth={viewWidth}
              loader={loader}
            />
            <GetBatchesPerDayGraph
              user={user}
              selectedDevices={selectedDevices}
              selectedSites={selectedSites}
              viewWidth={viewWidth}
              loader={loader}
            />
            <GetInventoryGraph
              user={user}
              selectedDevices={selectedDevices}
              viewWidth={viewWidth}
              loader={loader}
              onGraphClick={handleGraphClick}
              onTableData={handleInventoryTableData}
              soonToExpireThreshold={soonToExpireThresholdForInventoryGraph}
            />
            <GetAlarmsGraph
              user={user}
              onGraphClick={handleGraphClick}
              selectedDevices={selectedDevices}
              viewWidth={viewWidth}
              loader={loader}
              startDate={alarmsStartDate}
              stopDate={alarmsStopDate}
            /> */}

            <GetCanisterTrayRatioGraph
              user={user}
              selectedDevices={selectedDevices}
              loader={loader}
              startDate={canisterTrayRatioStartDate}
              stopDate={canisterTrayRatioStopDate}
              onStartDate={handleCanisterTrayRatioStartDate}
              onStopDate={handleCanisterTrayRatioStopDate}
              onShowMedicationsDispensed={handleShowMedicationsDispensed}
              onShowCanisterMedicationsDispensed={
                handleShowCanisterMedicationsDispensed
              }
              onShowTrayMedicationsDispensed={
                handleShowTrayMedicationsDispensed
              }
              showDropdowns={showDropdowns}
              onShowDropdowns={onShowDropdowns}
            />
          </div>
        </div>
      )}
      {!showMedicationsDispensed && selectedGraph && SelectedGraphView}
      {showMedicationsDispensed && (
        <MedicationsDispensed
          user={user}
          selectedDevices={selectedDevices}
          selectedSites={selectedSites}
          onShowMedicationsDispensed={handleShowMedicationsDispensed}
          showCanisterMedicationsDispensed={showCanisterMedicationsDispensed}
          onShowCanisterMedicationsDispensed={
            handleShowCanisterMedicationsDispensed
          }
          onShowTrayMedicationsDispensed={handleShowTrayMedicationsDispensed}
          onShowDropdowns={onShowDropdowns}
        />
      )}
    </div>
  );
};

export default TruPakDashboard;
