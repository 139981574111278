import React, {
  useState,
  useEffect,
  useReducer,
  useRef,
  useCallback,
  useContext,
  Fragment,
} from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import DropdownInput from "../../components/DropDownInput/DropdownInput";
import EV54Dashboard from "./EV54Dashboard/EV54Dashboard";
import PillSortDashboard from "./PillSortDashboard/PillSortDashboard";
import TruPakDashboard from "./TruPakDashboard/TruPakDashboard";
import { getDefaultDatesFormatted } from "./getDateFormats";
import WaitIndicator from "../../components/WaitIndicator";
import { ReactComponent as SearchIcon } from "../../assets/search-charts.svg";
import { ReactComponent as ClearSearchBar } from "../../assets/close-circle.svg";
import { ReactComponent as FilterIcon } from "../../assets/funnel.svg";
import { ReactComponent as CheveronDownIcon } from "../../assets/cheveron-down.svg";
import SearchBar from "./SearchBar/SearchBar";

import RoleCheckService from "../../services/RoleCheckService";
import StockLocationService from "../../services/StockLocationService";

import { ScreenContext } from "../../contexts/ScreenContext";
import styles from "./Dashboard.module.css";

const Dashboard = ({ user, dashboardReturnState }) => {
  const { t } = useTranslation("dashboards");

  const ref = useRef(null);

  const [sites, setSites] = useState([]);

  const [selectedSite, setSelectedSite] = useState(null);

  // const [siteFilter, setSiteFilter] = useState([]);

  // const [cleaningTasks, setCleaningTasks] = useState([]);

  const [collectingDataFromServer, setCollectingDataFromServer] =
    useState(false);

  // {startDate: Mon Feb 27 2023 16:09:47 GMT-0500 (Eastern Standard Time), endDate: Fri Mar 03 2023 16:09:47 GMT-0500 (Eastern Standard Time), key: 'selection'}
  const [dateRangeSelectionMainView, setDateRangeSelectionMainView] = useState(
    []
  );
  const [dashboardView, setDashboardView] = useState(null);

  const [selectedDevices, changeSelectedDevices] = useReducer(
    (array, { type, value }) => {
      switch (type) {
        case "reset":
          return [];
        case "add":
          if (!array.some((o) => o.type === value.type)) {
            return [...array, value];
          }
          return array;
        case "remove":
          return array.filter((o) => o.type !== value.type);
        default:
          return array;
      }
    },
    []
  );
  const [deviceList, setDeviceList] = useState([]);
  const [error, setError] = useState("");
  const [priceFileSelections, setPriceFileSelections] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchBarActive, setSearchBarActive] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showFilterModalQuick, setShowFilterModalQuick] = useState(false);
  const [showFilterModalAdvanced, setShowFilterModalAdvanced] = useState(false);
  const [dropDownList, setDropDownList] = useState([]);

  const [dashboardReturnStateLocal, setDashboardReturnStateLocal] =
    useState(dashboardReturnState);
  const [windowHeight, setWindowHeight] = useState(0);
  const [viewWidth, setViewWidth] = useState(window.innerWidth);
  const [showDropdowns, setShowDropdowns] = useState(true);
  const [sitesDropdownListDefaultValue, setSitesDropdownListDefaultValue] =
    useState(null);

  const dispensingDevicesDropdownList = [
    { description: "TruScript", type: "TruScript" },
    { description: "TruSort", type: "TruSort" },
    { description: "TruCard", type: "TruCard" },
    { description: "TruCheck", type: "TruCheck" },
    { description: "TruPak", type: "TruPak" },
    { description: t("Analytics"), type: "Analytics" },
    { description: t("Caregiver"), type: "Caregiver" },
  ];

  const { handleShowSmScreenHeader, smallScreen } = useContext(ScreenContext);

  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    if (dashboardView !== "TruPak" && sites?.length && !selectedSite) {
      let tempFilteredDevices = [];
      async function updateDevicesTruPak() {
        for (let i = 0; i < sites.length; i++) {
          await StockLocationService.getDispensableLocations(user, sites[i])
            .then((stockLocations) => {
              const filteredDevices = stockLocations.filter(
                (device) => device.type.type === "POUCH_PACKAGER"
              );
              tempFilteredDevices = [
                ...tempFilteredDevices,
                ...filteredDevices,
              ];
            })
            .catch((error) => {
              setError(error);
            });
        }
        if (
          sitesDropdownListDefaultValue !== "All sites" &&
          tempFilteredDevices?.length &&
          tempFilteredDevices.length > 1
        ) {
          tempFilteredDevices = [
            { name: "All devices" },
            ...tempFilteredDevices,
          ];
        }
      }
      updateDevicesTruPak();
    }
  }, [user, sites, dashboardView, sitesDropdownListDefaultValue]);

  // If mobile view, show mobile header (navbar hamburger, alarm bell, user profile photo)
  useEffect(() => {
    smScreen && handleShowSmScreenHeader(true);
  }, [smScreen]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setViewWidth(window.innerWidth);
    });
    return () =>
      window.removeEventListener("resize", () => {
        setViewWidth(window.innerWidth);
      });
  }, []);

  useEffect(() => {
    if (!dashboardReturnStateLocal) {
      if (user?.defaultSite?.name) {
        setSelectedSite(user.defaultSite);
      } else if (user.isEnterprise) {
        setSelectedSite(null); // all sites
      } else if (user.sites.length) {
        setSelectedSite(user.sites[0]);
      } else {
        setSelectedSite("no valid sites"); // no valid sites
      }
    }
  }, []);

  useEffect(() => {
    if (dashboardReturnStateLocal) {
      setDashboardView(dashboardReturnStateLocal?.returnView);
      setSelectedSite(dashboardReturnStateLocal?.selectedSite);
    }
  }, [dashboardReturnStateLocal]);

  // Get the pharmacy sites that this user has access rights to
  useEffect(() => {
    RoleCheckService.getAccessibleSites(user)
      .then((sites) => {
        sites.sort((a, b) => {
          if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
          }
          if (b.name.toUpperCase() < a.name.toUpperCase()) {
            return 1;
          }
          return 0;
        });
        setSites(sites);
      })
      .catch((error) => {
        // setError(error);
        console.log("error");
      });
  }, [user]);

  useEffect(() => {
    let tempDropdownList = [];
    if (user.isEnterprise) {
      tempDropdownList.push({
        description: "All sites",
        type: "All sites",
      });
    }
    sites.forEach((site) => {
      tempDropdownList.push({
        description: site.name,
        type: site.name,
      });
    });
    setDropDownList(tempDropdownList);
  }, [sites, user]);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    setWindowHeight(window.innerHeight);
  });

  useEffect(() => {
    let sitesDropdownListDefaultValueTemp;

    if (!dashboardReturnStateLocal) {
      dashboardReturnStateLocal?.selectedSite
        ? (sitesDropdownListDefaultValueTemp =
            dashboardReturnStateLocal.selectedSite?.name)
        : (sitesDropdownListDefaultValueTemp = "All sites");
    }

    if (!selectedSite && sites?.length) {
      sitesDropdownListDefaultValueTemp = "All sites";
    } else if (selectedSite) {
      sitesDropdownListDefaultValueTemp = selectedSite?.name;
    } else if (user?.defaultSite?.name) {
      sitesDropdownListDefaultValueTemp = user.defaultSite.name;
    } else if (user.isEnterprise) {
      sitesDropdownListDefaultValueTemp = "All sites";
    } else if (user.sites.length) {
      sitesDropdownListDefaultValueTemp = user.sites[0].name;
    } else {
      sitesDropdownListDefaultValueTemp = null;
    }
    setSitesDropdownListDefaultValue(sitesDropdownListDefaultValueTemp);
  }, [dashboardReturnStateLocal, selectedSite, user?.defaultSite]);

  // useEffect(() => {
  //   (async () => {
  //     if (user.isEnterprise) {
  //       const cleaningTasks = await StockLocationService.getCleaningTasksDue(
  //         user,
  //         []
  //       );
  //       setCleaningTasks(cleaningTasks);
  //     } else {
  //       const t = [];
  //       for (let i = 0; i < user.sites.length; i++) {
  //         t.push(user.sites[i]);
  //         const cleaningTasks = await StockLocationService.getCleaningTasksDue(
  //           user,
  //           t
  //         );
  //         setCleaningTasks(cleaningTasks);
  //       }
  //     }
  //   })();
  // }, [user]);

  useEffect(() => {
    setDefaultDates();
  }, []);

  // const getCleaningTasks = () => {
  //   let count = 0;
  //   if (
  //     siteFilter.some((o) => {
  //       return o.type === "enterprise";
  //     })
  //   ) {
  //     count = cleaningTasks.length;
  //   } else {
  //     const temp = [];
  //     for (let i = 0; i < siteFilter.length; i++) {
  //       const site = sites.find((o) => o.name === siteFilter[i].type);
  //       temp.push(site._id);
  //     }
  //     if (temp.length > 0) {
  //       count = cleaningTasks.filter((ct) =>
  //         temp.some((t) => {
  //           return t === ct.site._id;
  //         })
  //       ).length;
  //     }
  //   }
  //   if (count > 0) {
  //     return <DashboardLight icon={faBroom} data={count} />;
  //   }
  // };

  /*******************************DATES FUNCTIONALITY********************************/
  const defaultStartDate = getDefaultDatesFormatted().startDateString;
  const defaultEndDate = getDefaultDatesFormatted().endDateString;

  const setDefaultDates = () => {
    let tempDateRangeSelection = [...dateRangeSelectionMainView];

    tempDateRangeSelection[0] = {
      startDate: new Date(defaultStartDate),
      endDate: new Date(defaultEndDate),
      key: "selection",
    };

    setDateRangeSelectionMainView(tempDateRangeSelection);
  };

  const handleShowDropdowns = (boolVal) => {
    setShowDropdowns(boolVal);
  };

  const handleCollectingDataFromServer = (boolVal) => {
    setCollectingDataFromServer(boolVal);
  };
  /*******************************END DATES FUNCTIONALITY********************************/

  const handleDashboardView = (selectedDashboardView) => {
    setDashboardView(selectedDashboardView?.type);
    if (selectedDashboardView?.type === "TruSort") {
      handleShowSearchBar(true);
    } else {
      handleShowSearchBar(false);
      setSearchBarActive(false);
      setSearchText("");
    }
  };

  const handleActivateSearchBar = (e) => {
    if (showFilterModalQuick) {
      setShowFilterModalQuick(false);
    }
    if (showFilterModalAdvanced) {
      setShowFilterModalAdvanced(false);
    }
    if (!searchText) {
      setSearchBarActive(true);
      e.stopPropagation();
    }
  };

  const handleShowSearchBar = (boolVal) => {
    setShowSearchBar(boolVal);
  };

  const handleClearSearchBar = () => {
    setSearchText("");
  };

  const handleSearch = (searchText) => {
    // setSearchText(searchText.toUpperCase());
    setSearchText(searchText);
  };

  const handleOutsideSearchBoxClick = () => {
    if (showSearchBar && searchBarActive && !searchText) {
      setSearchBarActive(false);
    } else {
      if (showFilterModalQuick) {
        setShowFilterModalQuick(false);
      }
      if (showFilterModalAdvanced) {
        setShowFilterModalAdvanced(false);
      }
    }
  };

  const handleSelectedSite = (listItem) => {
    let updatedSelectedSite = sites.find(
      (site) => site?.name === listItem.description
    );
    setSelectedSite(updatedSelectedSite ? updatedSelectedSite : null);
  };

  const handleFilterModalQuick = () => {
    if (showFilterModalQuick) {
      setShowFilterModalQuick(false);
    } else {
      if (showFilterModalAdvanced) {
        setShowFilterModalAdvanced(false);
      }
      setShowFilterModalQuick(true);
    }
  };

  const handleFilterModalAdvanced = () => {
    if (showFilterModalAdvanced) {
      setShowFilterModalAdvanced(false);
    } else {
      if (showFilterModalQuick) {
        setShowFilterModalQuick(false);
      }
      setShowFilterModalAdvanced(true);
    }
  };

  const makeDashboardReturnStateLocalNull = () => {
    setDashboardReturnStateLocal(null);
  };

  // pass machineDashboardProps to each dispensing machine component
  const machineDashboardProps = {
    viewWidth: viewWidth,
    user: user,
    sites: sites,
    selectedSite: selectedSite,
    dashboardView: dashboardView,
    dateRangeSelectionMainView: dateRangeSelectionMainView,
    handleCollectingDataFromServer: handleCollectingDataFromServer,
    searchText,
    handleShowSearchBar,
    showFilterModalQuick,
    showFilterModalAdvanced,
    windowHeight,
    dashboardReturnStateLocal,
    makeDashboardReturnStateLocalNull,
    showDropdowns,
    onShowDropdowns: handleShowDropdowns,
  };

  const SearchBarComponent = (
    <div
      className={
        !searchBarActive
          ? `${styles.Dashboard__searchGroup} ${styles["Dashboard__searchGroup--inactive"]}`
          : !searchText
          ? `${styles.Dashboard__searchGroup} ${styles["Dashboard__searchGroup--active"]}`
          : `${styles.Dashboard__searchGroup} ${styles["Dashboard__searchGroup--active"]} ${styles["Dashboard__searchGroup--activeWithContent"]}`
      }
      onClick={handleActivateSearchBar}
    >
      <SearchIcon width="18px" height="18px" color="#323338" />
      {!searchBarActive ? (
        <p className={styles.Dashboard__searchGroupTitle}>{t("searchLabel")}</p>
      ) : (
        <SearchBar
          searchText={searchText}
          placeholder={t("searchPlaceholder")}
          onSearch={handleSearch}
          focus={searchBarActive}
          additionalInputStyle={
            searchText
              ? { backgroundColor: "#CEEBEE" }
              : { backgroundColor: "transparent" }
          }
        />
      )}
      {searchBarActive && searchText && (
        <div
          className={styles.Dashboard__clearSearchBarContainer}
          onClick={handleClearSearchBar}
        >
          <ClearSearchBar width="13px" height="13px" fill="#089bab" />
        </div>
      )}
    </div>
  );

  const FilterComponent = (
    <div
      className={
        !showFilterModalQuick
          ? styles.Dashboard__filterContainer
          : `${styles.Dashboard__filterContainer} ${styles["Dashboard__filterContainer--active"]}`
      }
    >
      <div
        className={styles.Dashboard__filterGroupQuick}
        onClick={handleFilterModalQuick}
      >
        <FilterIcon width="16px" height="16px" />
        <p className={styles.Dashboard__filterGroupTitle}>{t("filterLabel")}</p>
      </div>
      <div
        className={styles.Dashboard__filterGroupAdvanced}
        onClick={handleFilterModalAdvanced}
      >
        <div className={styles.Dashboard__cheveronDownIconDivisor}></div>
        <div className={styles.Dashboard__cheveronDownIconContainer}>
          <CheveronDownIcon width="10px" height="10px" stroke="#323338" />
        </div>
      </div>
    </div>
  );

  return (
    <Fragment>
      {collectingDataFromServer && (
        <WaitIndicator message={t("waitFetching")} />
      )}
      <main
        ref={ref}
        className={styles.Dashboard}
        onClick={handleOutsideSearchBoxClick}
      >
        {showDropdowns && (
          <section className={styles.Dashboard__headerRow}>
            <div className={styles.Dashboard__dropDownInputContainer}>
              <DropdownInput
                value={t(dashboardView)}
                validationText={""}
                isEnabled={true}
                placeholder={""}
                listElements={dispensingDevicesDropdownList || null}
                labelName={t("selectDashboardLabel")}
                onSelection={(listItem) => {
                  handleDashboardView(listItem);
                }}
                display={"description"}
                isDevices={true}
              />
            </div>
            {selectedSite !== "no valid sites" &&
              dashboardView !== "TruPak" && (
                <div className={styles.Dashboard__sitesDropdownContainer}>
                  <DropdownInput
                    value={sitesDropdownListDefaultValue}
                    validationText={""}
                    isEnabled={true}
                    placeholder={"selectSitePlaceholder"}
                    listElements={dropDownList || null}
                    labelName={t("selectSiteLabel")}
                    onSelection={(listItem) => {
                      handleSelectedSite(listItem);
                    }}
                    display={"description"}
                  />
                </div>
              )}
          </section>
        )}

        {/* <div className={styles.Dashboard__row}>{getCleaningTasks()}</div> */}

        <div
          className={
            showDropdowns
              ? styles.Dashboard__widgets
              : `${styles.Dashboard__widgets} 
        ${styles["Dashboard__widgets--details"]}`
          }
        >
          {dashboardView === "TruSort" && (
            <PillSortDashboard props={machineDashboardProps} />
          )}

          {dashboardView === "TruScript" && (
            <EV54Dashboard props={machineDashboardProps} />
          )}
          {dashboardView === "TruPak" && (
            <TruPakDashboard
              props={{
                ...machineDashboardProps,
              }}
            />
          )}
        </div>
      </main>
    </Fragment>
  );
};

export default Dashboard;
